<template>
  <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
    <button class="mapboxgl-ctrl-icon mapboxgl-style-switcher" @click="btnClick"></button>
    <div v-show="containerVisible" ref="controlContainer" class="mapboxgl-style-list">
      <button
        v-for="style in styles"
        :key="style.title"
        :class="style.title === mapType ? 'active': ''"
        @click="styleClicked(style.title)"
      >
        {{ style.title }}
      </button>
    </div>
  </div>
</template>

<script>

import { vm } from '@/main'
import { mapGetters } from 'vuex'
import { getPartnerData } from 'fleetmap-partners'
import layerManager, { routePlayLayer } from '@/views/map/mapbox/LayerManager'
import { resetImages } from '@/utils/lnglat'

export default {
  name: 'StyleSwitcherControl',
  data() {
    return {
      containerVisible: false,
      styles: [
        { title: this.$t('Streets'), uri: 'mapbox://styles/mapbox/streets-v12' },
        { title: this.$t('Satellite'), uri: 'mapbox://styles/mapbox/satellite-streets-v11' },
        { title: this.$t('Light'), uri: 'mapbox://styles/mapbox/light-v11' },
        { title: this.$t('Dark'), uri: 'mapbox://styles/mapbox/dark-v10' }
      ]
    }
  },
  computed: {
    ...mapGetters(['mapType', 'showSigns', 'allTripsSource', 'allTripsArrowsSource', 'historyMode']),
    map() { return vm.$static.map },
    geofencesVisible() { return vm.$store.state.map.showGeofences },
    lineGeofencesVisible() { return vm.$store.state.map.showLineGeofences },
    poisVisible() { return vm.$store.state.map.showPOIs }
  },
  async mounted() {
    let tileRoads = `https://mt0.google.com/vt/lyrs=m&hl=${navigator.language}&x={x}&y={y}&z={z}&s=Ga`
    let tileSatellite = `https://mt0.google.com/vt/lyrs=y&hl=${navigator.language}&x={x}&y={y}&z={z}&s=Ga`
    if (getPartnerData().googleMap) {
      if (getPartnerData().partnerId === 5) {
        /* const sessionRoad = await axios.post(`${pinmeApiBaseUrl}tile.googleapis.com/v1/createSession?key=${process.env.googleKey}`,
          {
            mapType: 'roadmap',
            language: 'fr-MA',
            region: 'MA'
          }).then(d => d.data.session)
        const {
          session,
          googleKey
        } = await axios.post(`${pinmeApiBaseUrl}tile.googleapis.com/v1/createSession?key=${process.env.googleKey}`,
          {
            mapType: 'satellite',
            language: 'fr-MA',
            region: 'MA',
            layerTypes: ['layerRoadmap']
          }).then(d => d.data)*/
        tileRoads = `https://d831cxdfrpk69.cloudfront.net/?x={x}&y={y}&z={z}&type=roads`
        tileSatellite = `https://d831cxdfrpk69.cloudfront.net/?x={x}&y={y}&z={z}&type=satellite`
      }
      this.styles = this.styles.concat([
        {
          title: this.$t('Google'),
          uri: {
            version: 8,
            sources: {
              'raster-tiles': {
                'type': 'raster',
                'tiles': [tileRoads],
                'tileSize': 256
              }
            },
            layers: [
              {
                'id': 'simple-tiles',
                'type': 'raster',
                'source': 'raster-tiles',
                'minzoom': 0,
                'maxzoom': 22
              }
            ],
            glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf'
          }},
        { title: this.$t('Google Hybrid'), uri: {
          version: 8,
          sources: {
            'raster-tiles': {
              'type': 'raster',
              'tiles': [tileSatellite],
              'tileSize': 256
            }
          },
          layers: [
            {
              'id': 'simple-tiles',
              'type': 'raster',
              'source': 'raster-tiles',
              'minzoom': 0,
              'maxzoom': 22
            }
          ],
          glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf'
        }}
      ])
    }
  },
  methods: {
    btnClick() {
      this.containerVisible = !this.containerVisible
    },
    async styleClicked(title) {
      resetImages()
      const style = this.styles.find(e => e.title === title).uri
      this.$store.dispatch('map/setType', title)
      this.$store.dispatch('map/setStyle', style)
      this.containerVisible = false
      if (this.historyMode) {
        const routeGeoJSON = vm.$static.map.getSource(this.allTripsSource)._data
        const points = vm.$static.map.getSource(this.allTripsArrowsSource)._data
        const routePlaySource = vm.$static.map.getSource(routePlayLayer)._data.features[0]
        vm.$static.map.setStyle(style)
        vm.$static.map.once('styledata', () => {
          this.$store.commit('map/createAllTripsLayer', { routeGeoJSON, points })
          layerManager.removeRoutePlayLayer()
          layerManager.addRoutePlayLayer()
          layerManager.updateRoutePlayLayerSource(routePlaySource)
        })
      } else { vm.$static.map.setStyle(style) }
    }
  }
}
</script>

<style scoped>
  .mapboxgl-style-list
  {
    display: block;
  }

  .mapboxgl-style-list button
  {
    background: none;
    border: none;
    cursor: pointer;
    display: block;
    font-size: 14px;
    padding: 8px 8px 6px;
    text-align: right;
    width: 100%;
  }

  /* ignore warning */
  .mapboxgl-style-list button.active
  {
    font-weight: bold;
  }

  .mapboxgl-style-list button:hover
  {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .mapboxgl-style-list button + button
  {
    border-top: 1px solid #ddd;
  }

  .mapboxgl-style-switcher
  {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTQuODQ5cHgiIGhlaWdodD0iNTQuODQ5cHgiIHZpZXdCb3g9IjAgMCA1NC44NDkgNTQuODQ5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1NC44NDkgNTQuODQ5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+PGc+PHBhdGggZD0iTTU0LjQ5NywzOS42MTRsLTEwLjM2My00LjQ5bC0xNC45MTcsNS45NjhjLTAuNTM3LDAuMjE0LTEuMTY1LDAuMzE5LTEuNzkzLDAuMzE5Yy0wLjYyNywwLTEuMjU0LTAuMTA0LTEuNzktMC4zMThsLTE0LjkyMS01Ljk2OEwwLjM1MSwzOS42MTRjLTAuNDcyLDAuMjAzLTAuNDY3LDAuNTI0LDAuMDEsMC43MTZMMjYuNTYsNTAuODFjMC40NzcsMC4xOTEsMS4yNTEsMC4xOTEsMS43MjksMEw1NC40ODgsNDAuMzNDNTQuOTY0LDQwLjEzOSw1NC45NjksMzkuODE3LDU0LjQ5NywzOS42MTR6Ii8+PHBhdGggZD0iTTU0LjQ5NywyNy41MTJsLTEwLjM2NC00LjQ5MWwtMTQuOTE2LDUuOTY2Yy0wLjUzNiwwLjIxNS0xLjE2NSwwLjMyMS0xLjc5MiwwLjMyMWMtMC42MjgsMC0xLjI1Ni0wLjEwNi0xLjc5My0wLjMyMWwtMTQuOTE4LTUuOTY2TDAuMzUxLDI3LjUxMmMtMC40NzIsMC4yMDMtMC40NjcsMC41MjMsMC4wMSwwLjcxNkwyNi41NiwzOC43MDZjMC40NzcsMC4xOSwxLjI1MSwwLjE5LDEuNzI5LDBsMjYuMTk5LTEwLjQ3OUM1NC45NjQsMjguMDM2LDU0Ljk2OSwyNy43MTYsNTQuNDk3LDI3LjUxMnoiLz48cGF0aCBkPSJNMC4zNjEsMTYuMTI1bDEzLjY2Miw1LjQ2NWwxMi41MzcsNS4wMTVjMC40NzcsMC4xOTEsMS4yNTEsMC4xOTEsMS43MjksMGwxMi41NDEtNS4wMTZsMTMuNjU4LTUuNDYzYzAuNDc3LTAuMTkxLDAuNDgtMC41MTEsMC4wMS0wLjcxNkwyOC4yNzcsNC4wNDhjLTAuNDcxLTAuMjA0LTEuMjM2LTAuMjA0LTEuNzA4LDBMMC4zNTEsMTUuNDFDLTAuMTIxLDE1LjYxNC0wLjExNiwxNS45MzUsMC4zNjEsMTYuMTI1eiIvPjwvZz48L2c+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjwvc3ZnPg==);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
  }

  @media only screen and (max-width: 768px) {
    .mapboxgl-style-list button
    {
      font-size: 24px;
      padding-top: 5px;
      padding-bottom: 26px;
    }
  }

</style>
