import { positionsSource } from '@/utils/consts'
import store from '../../../store'
export default {
  id: 'vehicleLabels',
  type: 'symbol',
  source: positionsSource,
  filter: ['!=', 'cluster', true],
  layout: {
    'text-font': ['Arial Unicode MS Bold', 'Open Sans Regular'],
    'text-size': store.getters.labelTextSize,
    'text-variable-anchor': ['top', 'bottom', 'left', 'right', 'top-left', 'top-right', 'bottom-left', 'bottom-right'],
    'text-radial-offset': ['interpolate', ['linear'], ['zoom'], 6, 1, 10, 2, 16, 3],
    'text-justify': 'auto',
    'text-field': ['get', 'text'],
    'text-transform': 'uppercase'
  },
  paint: {
    'text-halo-width': 1,
    'text-halo-blur': 1,
    'text-color': ['get', 'labelColor'],
    'text-halo-color': ['case',
      ['<', ['+',
        ['*', 0.299, ['get', 'labelColorR']],
        ['*', 0.587, ['get', 'labelColorG']],
        ['*', 0.114, ['get', 'labelColorB']]
      ], 144], 'white', 'black']
  }
}
