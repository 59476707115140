<template>
  <div v-if="dataContainerTabActive === '1'">
    <div class="mobileScroll">
      <el-table
        id="driverTable"
        v-el-table-infinite-scroll="load"
        style="padding: 10px"
        highlight-current-row
        :cell-style="cellStyle"
        :data="filteredDrivers"
        :show-header="false"
        :height="height"
        @current-change="driverSelected"
      >
        <el-table-column
          prop="positions"
          label=""
          width="10"
          heigth="10"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          sortable=""
          heigth="1"
        >
          <template slot-scope="scope">
            <div class="driverInfo">
              <span class="driverName">{{ scope.row.name }} </span>
              <span class="driverGroupName">{{ scope.row.groupName || '' }} </span>
            </div>
            <div style="line-height: normal">
              <span class="driverVehicleName"><i class="fas fa-car driverVehicleIcon"></i> {{ getVehicleName(scope.row) }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { serverBus, vm } from '@/main'
import styles from '../../styles/element-variables.scss'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import * as utils from '../../utils/utils'

export default {
  name: 'DriverTable',
  props: {
    filterKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      count: 50
    }
  },
  computed: {
    ...mapGetters(['historyMode', 'drivers', 'devices', 'dataContainerTabActive']),
    height() {
      return 'calc(100vh - ' + styles.driverListHeaderHeight + ')'
    },
    map: function() {
      return vm.$static.map
    },
    filteredDrivers: function() {
      const self = this
      const filterKey = this.filterKey && this.filterKey.toLowerCase()
      let drivers = this.drivers
      if (filterKey) {
        drivers = drivers.filter(function(row) {
          return Object.keys(row).some(function(key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
          }) || self.getVehicleName(row).toLowerCase().indexOf(filterKey) > -1
        })
      }
      drivers = drivers.sort((a, b) => {
        a = self.getDriverStateOrder(a) + ' ' + a['name']
        b = self.getDriverStateOrder(b) + ' ' + b['name']

        return (a === b ? 0 : a.localeCompare(b))
      })
      return drivers.slice(0, this.count)
    }
  },
  methods: {
    load() {
      this.count += 20
    },
    getDriverStateOrder: function(driver) {
      const device = this.getVehicle(driver)
      if (device && device.driver && device.driver.id === driver.id) {
        const deviceState = utils.getDeviceState(device.position)
        if (deviceState === 'Moving' || deviceState === 'Idle') {
          return 0
        }
      }

      return 1
    },
    cellStyle(row) {
      let result = 'padding: 0; '
      if (row.columnIndex === 0) {
        result += 'background-color: ' + this.getBgColor(row.row)
      }
      return result
    },
    getBgColor: function(driver) {
      const device = this.getVehicle(driver)
      if (device && device.driver && device.driver.id === driver.id) {
        const deviceState = utils.getDeviceState(device.position)
        if (deviceState === 'Disconnected') {
          return 'Gray'
        }
        if (deviceState === 'Moving') {
          return styles.success
        }
        if (deviceState === 'Idle') {
          return styles.warning
        }
      }
      return 'Gray'
    },
    driverSelected(driver) {
      if (driver && driver.attributes.deviceId && !this.historyMode) {
        const device = this.getVehicle(driver)
        if (device) {
          this.selectedDevice = device
          Vue.$log.debug('device=', device)
          vm.$store.dispatch('transient/togglePlaying').then(() => {
            serverBus.$emit('deviceSelected', device)
            serverBus.$emit('deviceSelectedOnMap', device)
          })
        }
      }
    },
    getVehicleName(driver) {
      const device = this.getVehicle(driver)
      return device ? device.name : ''
    },
    getVehicle(driver) {
      if (!driver.attributes.deviceId) {
        return undefined
      }

      return this.devices.find(d => d.id === driver.attributes.deviceId)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/element-variables.scss';

  .driverInfo {
    padding: 3px 0 0
  }

  .driverName {
    font-weight: bold
  }

  .driverGroupName {
    float: right; font-size: smaller
  }

  .driverVehicleName {
    font-size: 12px; word-break: normal;
  }

  .driverVehicleIcon {
    width: 15px;
    color: #055AE5;
  }
</style>
