<template>
  <div class="mainContainer">
    <div style="float: right">
      <i :class="tableCollapsed?'el-icon-s-unfold':'el-icon-s-fold'" :style="`color:${colors.info}`" @click="$store.dispatch('map/toggleTableCollapsed')"></i>
    </div>
    <div v-if="!tableCollapsed" class="dd-body-inner">
      <logo-svg v-if="hasSVG" :class="'logo ' + logoClassType"></logo-svg>
      <img v-else :class="'logo ' + logoClassType" height="44" :src="logoImage" alt="">
      <el-input v-model="filterKey" size="mini" class="input" type="text" :placeholder="$t('vehicleList.search')" suffix-icon="el-icon-search" clearable />
      <el-tabs v-model="selectedTab" stretch @tab-click="tabClicked">
        <el-tab-pane name="map">
          <span slot="label">
            <el-tooltip :content="$t('settings.vehicles')">
              <i class="fas fa-car"></i>
            </el-tooltip>
          </span>
          <vehicle-table
            :filter-key="filterKey"
          />
        </el-tab-pane>
        <el-tab-pane name="drivers">
          <span slot="label">
            <el-tooltip :content="$t('settings.drivers')">
              <i class="fas fa-address-card"></i>
            </el-tooltip>
          </span>
          <driver-table :filter-key="filterKey"></driver-table>
        </el-tab-pane>
        <el-tab-pane name="geofence">
          <span slot="label">
            <el-tooltip :content="$t('settings.geofences')">
              <i class="fas fa-map-marked"></i>
            </el-tooltip>
          </span>
          <geofence-table :filter-key="filterKey"></geofence-table>
        </el-tab-pane>
        <el-tab-pane name="alerts">
          <span slot="label">
            <el-tooltip :content="$t('settings.alerts')">
              <el-badge :value="unreadItems" :hidden="unreadItems === 0" :max="99">
                <i class="fas fa-bell"></i>
              </el-badge>
            </el-tooltip>
          </span>
          <alert-table ref="alertsTable" :filter-key="filterKey"></alert-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>

import * as lnglat from '../../utils/lnglat'
import AlertTable from './AlertTable'
import GeofenceTable from './GeofenceTable'
import DriverTable from './DriverTable'
import VehicleTable from './VehicleTable'
import * as partner from '../../utils/partner'
import LogoSvg from '../../layout/components/LogoSvg'
import { mapGetters } from 'vuex'
import styles from '../../styles/element-variables.scss'
import * as alertType from '../../alerts/alertType'

export default {
  name: 'DataContainer',
  components: { VehicleTable, AlertTable, GeofenceTable, DriverTable, LogoSvg },
  data() {
    return {
      selectedTab: 'map',
      filterKey: ''
    }
  },
  computed: {
    ...mapGetters(['tableCollapsed', 'alerts', 'historyMode']),
    unreadItems: {
      get() { return this.$store.getters.unreadItems },
      set(value) { this.$store.commit('setUnreadItems', value) }
    },
    hasSVG() {
      return partner.hasSVG()
    },
    isMobile() {
      return lnglat.isMobile()
    },
    colors() {
      return styles
    },
    logoImage() {
      return partner.getLogo()
    },
    logoClassType() {
      return this.isMobile ? 'logoMobile' : 'logoDesktop'
    }
  },
  watch: {
    'selectedTab': function(val) { // Monitor switch status-plan
      if (val === 'alerts') {
        if (!this.unreadItems) {
          this.$store.commit('transient/SET_LOADING_EVENTS', true)
          const userAlerts = this.alerts.map(a => this.getAlertType(a))
          const _alertTypes = userAlerts.map(t => {
            return alertType.alertTypes.includes(t) ? t : t.split(',')
          }).flat()
          this.$store.dispatch('transient/fetchEvents', {
            start: new Date(new Date().getTime() - 1000 * 60 * 60),
            end: new Date(),
            types: [...new Set(_alertTypes.map(t => alertType.traccarAlertTypes.includes(t) ? t : 'alarm'))]
          }).finally(() => this.$store.commit('transient/SET_LOADING_EVENTS', false))
        }
      }
    },
    filterKey() {
      if (this.filterKey === '' || this.selectedTab === 'geofence') {
        this.$store.commit('map/FILTER_GEOFENCES', this.filterKey)
      }
    }
  },
  methods: {
    tabClicked(e) {
      this.$store.commit('SET_DATACONTAINER_TABACTIVE', e.index)
    },
    getAlertType(item) {
      if (item.notification.type === 'alarm') {
        return item.notification.attributes.alarms
      } else {
        return item.notification.type
      }
    }
  }
}
</script>
<style lang="scss" scoped>

  .dd-body-inner {
    padding: 5px;
  }
  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .logoMobile {
    margin-top: 25px;
    margin-bottom: 0;
  }
  .logoDesktop {
    margin-bottom: 5px;
  }
  .input {
    color: #979797;
  }

  .mainContainer {
    background-color: #F8F9FE;
  }
  .dd-body-inner {
    padding: 5px;
  }
  .label-tab {
    text-align: center;
    font-size: 20px;
  }
  .hidden_header {
    display: none;
  }
  .collapse {
    float: left;
  }
</style>
