import { serverBus, vm } from '@/main'
import Vue from 'vue'
import store from '@/store'
import routeLayers from '@/views/map/mapbox/layers/RouteLayers'
import vehicleLayer from '@/views/map/mapbox/VehiclesLayer'
import vehicleLabelsLayer from '@/views/map/mapbox/VehicleLabelsLayer'
import { positionsSource } from '@/utils/consts'
import * as consts from '@/utils/consts'
import vehiclesLayer from '@/views/map/mapbox/VehiclesLayer'
import geofencesLayer from './layers/GeofencesLayer'
import eventsLayer from './layers/EventsLayer'
import * as utils from '@/utils/utils'
import * as angles from 'angles'
import { updateDonuts, getGeoJSON } from '@/utils/lnglat'
import WazeLayer from '@/views/map/mapbox/layers/WazeLayer'
import { animatingFeatures } from '@/utils/animation'

const buildings3d = '3d-buildings'
const currentRouteLayer = 'current-route'
const currentRouteSource = 'current-route'

export const routePlayLayer = 'routePlayLayer'
const routePlayVehicleLayer = { ...vehicleLayer }

const gray = ['==', ['get', 'color'], 'gray']
const green = ['==', ['get', 'color'], 'green']
const yellow = ['==', ['get', 'color'], 'yellow']
const red = ['==', ['get', 'color'], 'red']

const geofencesLayerId = 'geofences'
const eventsLayerId = 'events'

routePlayVehicleLayer.id = routePlayLayer
routePlayVehicleLayer.source = routePlayLayer
delete routePlayVehicleLayer.filter

function updateSource(source, feature) {
  const fSource = vm.$static.map.getSource(source)
  if (fSource) {
    fSource.setData({ type: 'FeatureCollection', features: [feature] })
  }
}

function addAnimationSource(features) {
  if (!vm.$static.map.getSource('animationSource')) {
    vm.$static.map.addSource('animationSource', {
      type: 'geojson',
      data: { type: 'FeatureCollection', features }
    })
  }
}

export function hideLayer(layer, hide) {
  setVisible(layer, !hide)
}

export function setVisible(layer, value) {
  const visibility = value ? 'visible' : 'none'
  if (vm.$static.map.getLayer(layer)) {
    vm.$static.map.setLayoutProperty(layer, 'visibility', visibility)
  }
}
function refreshSources() {
  const fSource = vm.$static.map.getSource(positionsSource)
  if (fSource) {
    fSource.setData({
      type: 'FeatureCollection',
      features: vm.$static.positionsSource.features.filter(f => !animatingFeatures.includes(f))
    })
  }
}

function refreshLayersVisibility() {
  if (store.getters.vehicles3dEnabled) {
    const on3d = vm.$static.map.getPitch() > 0
    hideLayer(vehiclesLayer.id, on3d)
  }
  setVisible(vehicleLabelsLayer.id,
    !store.getters.historyMode && store.state.settings.showLabels)
  setVisible(vehiclesLayer.id, !store.getters.historyMode)
  setVisible(routePlayVehicleLayer.id, store.getters.historyMode)
  setVisible(buildings3d, store.state.map.show3dBuildings)
  setVisible('signs', store.state.map.showSigns)
}

export default {
  updateFeature(feature, position) {
    feature.properties = { ...feature.properties, ...position }
    feature.properties.color = utils.getDeviceColor(utils.getDeviceState(position))
    feature.properties.courseMinusBearing = angles.normalize(position.course - feature.properties.bearing)
  },
  refreshLayers(loadingCount = -1) {
    if (loadingCount !== -1 && loadingCount < 3) { return }
    refreshSources()
    refreshLayersVisibility()
    updateDonuts()
  },
  hideLayer(layer, hide) {
    setVisible(layer, !hide)
  },
  addRemoveSignsLayer(add) {
    if (add) {
      vm.$static.map.addSource('signs', {
        'type': 'vector',
        'tiles': [
          'https://tiles.mapillary.com/maps/vtp/mly_map_feature_traffic_sign/2/{z}/{x}/{y}?access_token=MLY|4838573629515311|558ad8d7875d3931e91e58c06953bb44'
        ],
        minzoom: 14,
        maxzoom: 14
      })
      vm.$static.map.addLayer({
        id: 'signs',
        type: 'symbol',
        'source-layer': 'traffic_sign',
        source: 'signs',
        layout: {
          'icon-image': ['get', 'value']
        }
      })
    } else {
      vm.$static.map.removeLayer('signs')
      vm.$static.map.removeSource('signs')
    }
  },
  addRemoveWazeLayer(add) {
    if (add && !vm.$static.map.getSource('waze', WazeLayer.source)) {
      vm.$static.map.addSource('waze', WazeLayer.source)
      vm.$static.map.addSource('wazeAlerts', WazeLayer.alertsSource)
      vm.$static.map.addLayer(WazeLayer.layer)
      vm.$static.map.addLayer(WazeLayer.alertsLayer)
    } else if (!add && vm.$static.map.getLayer(WazeLayer.layer.id)) {
      vm.$static.map.removeLayer(WazeLayer.layer.id)
      vm.$static.map.removeLayer(WazeLayer.alertsLayer.id)
      vm.$static.map.removeSource('waze')
      vm.$static.map.removeSource('wazeAlerts')
    }
  },
  addLayers(map) {
    this.addRemoveWazeLayer(store.getters.showWaze || store.getters.showTraffic)
    WazeLayer.refresh(map).then()
    if (!map.getSource('signs')) {
      this.addRemoveSignsLayer(true)
    }
    if (!map.getLayer(geofencesLayerId)) {
      this.fetchGeofences(map)
    }
    if (!map.getSource(positionsSource)) {
      map.addSource(positionsSource, {
        type: 'geojson',
        data: vm.$static.positionsSource,
        cluster: true,
        clusterMaxZoom: consts.detailedZoom - 1, // Max zoom to cluster points on
        clusterRadius: 25,
        clusterProperties: { // keep separate counts for each magnitude category in a cluster
          'gray': ['+', ['case', gray, 1, 0]],
          'yellow': ['+', ['case', yellow, 1, 0]],
          'red': ['+', ['case', red, 1, 0]],
          'green': ['+', ['case', green, 1, 0]]
        }
      })
    } else { Vue.$log.warn(positionsSource, ' already exists...') }
    if (!map.getSource(currentRouteSource)) {
      const lineString = { type: 'LineString', coordinates: [] }
      map.addSource(currentRouteSource, {
        type: 'geojson',
        lineMetrics: true,
        data: getGeoJSON(lineString)
      })
    } else { Vue.$log.warn(currentRouteSource, ' already exists...') }
    if (!map.getLayer(buildings3d) && map.getSource('composite')) {
      vm.$static.map.addLayer({
        id: buildings3d,
        source: 'composite',
        'source-layer': 'building',
        filter: ['==', 'extrude', 'true'],
        type: 'fill-extrusion',
        minzoom: 15,
        paint: {
          'fill-extrusion-color': '#aaa',
          'fill-extrusion-height': [
            'interpolate', ['linear'], ['zoom'],
            15, 0,
            15.05, ['get', 'height']
          ],
          'fill-extrusion-base': [
            'interpolate', ['linear'], ['zoom'],
            15, 0,
            15.05, ['get', 'min_height']
          ],
          'fill-extrusion-opacity': 0.6
        }
      })
    } else {
      Vue.$log.warn('3dbuildings layer already exists...')
    }
    if (!map.getLayer(currentRouteLayer)) {
      vm.$static.map.addLayer(routeLayers.currentRouteLayer(currentRouteLayer))
    } else {
      Vue.$log.warn('currentRouteLayer layer already exists...')
    }
    if (!map.getLayer(vehiclesLayer.id)) {
      vm.$static.map.addLayer(vehiclesLayer)
      vm.$static.map.addLayer(vehicleLabelsLayer)
    } else {
      Vue.$log.warn('vehiclesLayer already exists...')
    }
    if (!map.getLayer(eventsLayerId)) {
      this.fetchEvents(map)
    }
    this.addRoutePlayLayer()
    this.refreshLayers()
  },
  fetchEvents(map) {
    if (!map.getSource('events')) {
      map.addSource('events', {
        'type': 'geojson',
        'data': vm.$static.eventsSource
      })
      if (!map.getLayer(eventsLayerId)) {
        map.addLayer(eventsLayer.events)
      }
    }
  },
  fetchGeofences(map) {
    if (!map.getSource('geofences')) {
      map.addSource('geofences', {
        type: 'geojson',
        data: vm.$static.geofencesSource,
        tolerance: 0.00001
      })
    }
    if (!map.getLayer(geofencesLayerId)) {
      Vue.$log.debug('adding geofences layer', store.state.map.showGeofences)
      map.addLayer(geofencesLayer.geofences)
      if (store.state.user.geofences.length < 30000) {
        map.addLayer(geofencesLayer.geofencesFill)
        map.addLayer(geofencesLayer.geofencesLabels)
        map.addLayer(geofencesLayer.geofencesLines)
        map.addLayer(geofencesLayer.geofencesLinesLabels)
        map.addLayer(geofencesLayer.poiCircles)
      }
      map.addLayer(geofencesLayer.pois(store))
      store.dispatch('map/setLineGeofencesVisible').then()
      store.dispatch('map/setGeofencesVisible').then()
      store.dispatch('map/setPOIsVisible').then()
    }
  },
  onClickTouchUnclustered(e) {
    Vue.$log.debug(e)
    const feature = e.features[0]
    const device = store.getters.deviceById(feature.properties.deviceId)
    if (device) {
      serverBus.$emit('deviceSelected', device)
      serverBus.$emit('deviceSelectedOnMap', device)
    }
  },
  getAnimationLayer() {
    return vm.$static.map.getLayer('animation')
  },
  addAnimationLayer() {
    addAnimationSource([])
    const animationLayer = { ...vehicleLayer }
    animationLayer.id = 'animation'
    animationLayer.source = 'animationSource'

    vm.$static.map.addLayer(animationLayer)
    vm.$static.map.on('touchstart', animationLayer.id, this.onClickTouchUnclustered)
    vm.$static.map.on('click', animationLayer.id, this.onClickTouchUnclustered)
  },
  removeCurrentRouteLayer() {
    if (vm.$static.map.getSource(currentRouteSource)) {
      Vue.$log.debug('RemoveCurrentRouteLayer', vm.$static.map.getSource(currentRouteSource))
      this.updateCurrentRouteSource([])
    }
  },
  updateAnimLayerSource(features) {
    const fSource = vm.$static.map.getSource('animationSource')
    if (fSource) {
      fSource.setData({ type: 'FeatureCollection', features })
    }
  },
  updateRoutePlayLayerSource(feature) {
    updateSource(routePlayLayer, feature)
  },
  removeRoutePlayLayer() {
    if (vm.$static.map.getLayer(routePlayVehicleLayer.id)) { vm.$static.map.removeLayer(routePlayVehicleLayer.id) }
  },
  addRoutePlayLayer() {
    if (!vm.$static.map.getSource(routePlayLayer)) {
      const source = { type: 'geojson', data: { type: 'FeatureCollection', features: [] }}
      vm.$static.map.addSource(routePlayLayer, source)
    } else {
      Vue.$log.warn('source', routePlayLayer, 'already exists...')
    }
    if (!vm.$static.map.getLayer(routePlayVehicleLayer.id)) {
      vm.$static.map.addLayer(routePlayVehicleLayer)
    } else {
      Vue.$log.warn('layer', routePlayVehicleLayer.id, 'already exists...')
    }
  },
  updateCurrentRouteSource(positions) {
    if (!positions) return
    const lineString = { type: 'LineString', coordinates: positions.map(p => [p.longitude, p.latitude]) }
    const routeGeoJSON = getGeoJSON(lineString)
    if (!vm.$static.map.getSource(currentRouteSource)) {
      vm.$static.map.addSource(currentRouteSource, {
        type: 'geojson',
        lineMetrics: true,
        data: routeGeoJSON
      })
    }
    vm.$static.map.getSource(currentRouteSource).setData(routeGeoJSON)
  }
}
