<template>
  <div>
    <a :href="imageLink" target="_blank">
      <img style="width:100%; margin-top: 13px; margin-bottom: 0" :src="imageUrl" alt="" />
    </a>
    <div style="padding-left: 6px;padding-right: 6px;">
      <copy-coordinates :current-position="{latitude: lngLat[0], longitude: lngLat[1]}" :url-street="imageLink" />
      <div class="title">
        {{ properties.title }}
      </div>
      <div class="subtitle">
        {{ properties.description }}
      </div>
      <div style="float: right">
        <el-link :href="navigateUrl" target="_blank">Navegar</el-link>
      </div>
      <div style="float: left">
        <el-tooltip placement="bottom" :content="$t('Move')">
          <i class="fa-regular fa-arrows-up-down-left-right" @click="handleMove()"></i>
        </el-tooltip>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import { isMobile } from '@/utils/lnglat'
import { serverBus } from '@/main'
import CopyCoordinates from '../../components/CopyCoordinates'
import { getServerHost } from '@/api'
import * as event from '@/events'
import { mapGetters } from 'vuex'

export default {
  name: 'PoiPopUp',
  components: { CopyCoordinates },
  data: function() {
    return {
      imageLink: '',
      imageUrl: '',
      navigateUrl: ''
    }
  },
  computed: {
    ...mapGetters(['geofences'])
  },
  async mounted() {
    const lat = this.lngLat[0].toFixed(6)
    const lon = this.lngLat[1].toFixed(6)
    const ll = `${lat},${lon}`
    this.navigateUrl = `https://maps.google.com/maps?daddr=${ll}`
    this.imageUrl = `https://${getServerHost()}/pinmeapi/streetview/image?&latitude=${lat}&longitude=${lon}`
    this.imageLink = `https://${getServerHost()}/pinmeapi/streetview/imageUrl?&latitude=${lat}&longitude=${lon}`
  },
  beforeDestroy() {
    Vue.$log.debug('destroying PoiPopUp', this.properties)
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.lngLat[1].toFixed(6) + ',' + this.lngLat[0].toFixed(6))
      if (isMobile()) {
        serverBus.$emit('message', this.lngLat[1].toFixed(6) + ',' + this.lngLat[0].toFixed(6))
      }
    },
    handleMove() {
      const poi = this.geofences.find(g => g.id === this.properties.id)
      serverBus.$emit(event.movePOI, poi)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/element-variables.scss';

  .left {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #32325D;
    padding-left: 5px;
    overflow: auto;
  }
  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #32325D;
    padding-bottom: 8px;
    overflow: auto;
  }
  .right {
    padding:10px;
    float: right;
  }
  .coordsIcon{
    float: right;
    color: $--color-primary;
    padding-top: 15px;
    padding-right: 10px;
  }
</style>
