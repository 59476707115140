<template>
  <div>
    <div style="margin-bottom: 5px;">
      <el-row type="flex" justify="space-around">
        <el-col :span="4">
          <el-tooltip :content="$t('settings.pois')">
            <el-button
              id="btnPOI"
              :round="buttonRound"
              :size="buttonSize"
              :style="'border-width: 2px; border-color:' + isPOISelected"
              @click="handleFilterType('POI')"
            ><i class="fas fa-map-marker-alt" :style="'color:' + isPOISelected" /></el-button>
          </el-tooltip>
        </el-col>
        <el-col :span="4">
          <el-tooltip :content="$t('settings.geofences')">
            <el-button
              id="btnGeofence"
              :round="buttonRound"
              :size="buttonSize"
              :style="'border-width: 2px; border-color:' + isGeofenceSelected"
              @click="handleFilterType('GEOFENCE')"
            ><i class="fas fa-draw-polygon" :style="'color:' + isGeofenceSelected" /></el-button>
          </el-tooltip>
        </el-col>
        <el-col :span="4">
          <el-tooltip :content="$t('settings.linegeofences')">
            <el-button
              id="btnLine"
              :round="buttonRound"
              :size="buttonSize"
              :style="'border-width: 2px; border-color:' + isLineGeofenceSelected"
              @click="handleFilterType('LINE')"
            ><i class="fas fa-wave-square" :style="'color:' + isLineGeofenceSelected" /></el-button>
          </el-tooltip>
        </el-col>
      </el-row>
      <!--<el-switch
         v-model="showPOIsLayer"
         :active-text="$t('poiTable.showPOIs')"
         inactive-text=""
       ></el-switch>-->
    </div>
    <div>
      <el-table
        id="geofenceTable"
        v-el-table-infinite-scroll="load"
        style="padding-top: 10px"
        highlight-current-row
        :data="filteredGeofences"
        :show-header="false"
        :height="height"
        @current-change="geofenceSelected"
        @cell-dblclick="handleEdit"
      >
        <el-table-column label="" width="40">
          <template slot-scope="scope">
            <i
              v-if="getType(scope.row)!=='poi'"
              :style="'color: '+(scope.row.attributes.color ? scope.row.attributes.color : '#3232b4')"
              :class="geofenceImageType(scope.row)"
            >
            </i>
            <div v-else>
              <img style="vertical-align: middle;" :src="scope.row.attributes.img" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div :style="scope.row.groupName ? 'height: 15px;' : ''"><span style="width: 150px; font-size: small; font-weight: bold; overflow: hidden; white-space: nowrap;text-overflow: ellipsis">{{ scope.row.name }}</span></div>
            <div v-if="scope.row.groupName" style="height: 20px;"><span style="font-size: smaller; overflow: hidden; white-space: nowrap;text-overflow: ellipsis">{{ scope.row.groupName }}</span></div>
          </template>
        </el-table-column>
        <el-table-column width="25" align="left">
          <template slot-scope="scope">
            <span class="el-dropdown-link" style="padding: 1px">
              <a @click="handleToggleVisibility(scope.row, false)"><i style="font-size: 11px" :class="scope.row.visible ? 'fas fa-eye' : 'fas fa-eye-slash'"></i></a>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="" width="30" align="left">
          <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link" style="padding: 1px">
                <i class="fas fa-ellipsis-v"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="handleEdit(scope.row)"><i class="fas fa-pen" />{{ $t('poiTable.edit_poi') }}</el-dropdown-item>
                <el-dropdown-item @click.native="handleMove(scope.row)"><i class="fas fa-up-down-left-right"></i>{{ $t('Move') }}</el-dropdown-item>
                <el-dropdown-item @click.native="handleDelete(scope.row)"><i class="fas fa-trash" />{{ $t('poiTable.delete_poi') }}</el-dropdown-item>
                <el-dropdown-item @click.native="handleToggleVisibility(scope.row, false)"><i :class="getVisibilityIcon(scope.row)" />{{ scope.row.visible ? $t('poiTable.hide_geofence') : $t('poiTable.show_geofence') }}</el-dropdown-item>
                <el-dropdown-item @click.native="handleToggleVisibility(scope.row, true)"><i :class="getVisibilityIcon(scope.row)"></i>{{ scope.row.visible ? $t('poiTable.hide_all') : $t('poiTable.show_all') }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { serverBus, vm } from '@/main'
import { traccar } from '@/api/traccar-api'
import Vue from 'vue'
import * as lnglat from '../../utils/lnglat'
import styles from '../../styles/element-variables.scss'
import * as event from '../../events'

export default {
  name: 'GeofenceTable',
  props: {
    filterKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      count: 20
    }
  },
  computed: {
    map: function() { return vm.$static.map },
    buttonRound() {
      return !this.isMobile
    },
    buttonSize() {
      return this.isMobile ? 'large' : 'mini'
    },
    height() {
      return `calc(100vh - ${styles.geofenceListHeaderHeight})`
    },
    geofences: function() {
      return vm.$store.state.user.geofences.filter(g => g && g.area &&
          (
            (g.area.startsWith('POLYGON') && this.showGeofenceLayer) ||
            (g.area.startsWith('CIRCLE') && this.showPOIsLayer) ||
            (g.area.startsWith('LINESTRING') && this.showLineGeofenceLayer)
          )
      )
    },
    geofencesSource() { return this.$root.$static.geofencesSource },
    filteredGeofences: function() {
      const filterKey = this.filterKey && this.filterKey.toLowerCase()
      let geofences = this.geofences
      if (filterKey) {
        geofences = geofences.filter(row => row.name.toLowerCase().indexOf(filterKey) > -1)
      }
      geofences = geofences.sort((a, b) => (a.name > b.name) ? 1 : -1)

      return geofences.slice(0, this.count)
    },
    showPOIsLayer: {
      get() { return !!vm.$store.state.map.showPOIs },
      set() { this.togglePOIs() }
    },
    showGeofenceLayer: {
      get() { return !!vm.$store.state.map.showGeofences },
      set() { this.toggleGeofences() }
    },
    showLineGeofenceLayer: {
      get() { return !!vm.$store.state.map.showLineGeofences },
      set() { this.toggleLineGeofences() }
    },
    isPOISelected() {
      return this.showPOIsLayer ? '#055AE5' : 'gray'
    },
    isGeofenceSelected() {
      return this.showGeofenceLayer ? '#055AE5' : 'gray'
    },
    isLineGeofenceSelected() {
      return this.showLineGeofenceLayer ? '#055AE5' : 'gray'
    }
  },
  methods: {
    load() {
      this.count += 20
    },
    geofenceSelected: function(geofence) {
      if (geofence) {
        Vue.$log.debug('geofence=', geofence)
        serverBus.$emit('areaSelected', geofence)
      }
    },
    togglePOIs: function() {
      vm.$store.dispatch('map/togglePOIs')
    },
    toggleGeofences: function() {
      vm.$store.dispatch('map/toggleGeofences')
    },
    toggleLineGeofences: function() {
      vm.$store.dispatch('map/toggleLineGeofences')
    },
    geofenceImageType(row) {
      return row.area.startsWith('CIRCLE') ? 'fas fa-map-marker-alt' : (row.area.startsWith('LINE') ? 'iconGeofence fas fa-wave-square' : 'iconGeofence fas fa-draw-polygon')
    },
    handleFilterType: function(type) {
      if (type === 'POI') {
        this.togglePOIs()
      }
      if (type === 'GEOFENCE') {
        this.toggleGeofences()
      }
      if (type === 'LINE') {
        this.toggleLineGeofences()
      }
    },
    handleToggleVisibility(geofence, all) {
      const visible = !geofence.visible
      if (all) {
        for (const g of (this.filterKey ? this.filteredGeofences : this.geofences)) {
          this.toggleGeofenceVisibility(g, visible)
        }
      } else {
        this.toggleGeofenceVisibility(geofence, visible)
      }
      lnglat.refreshGeofences()
    },
    toggleGeofenceVisibility(geofence, visible) {
      this.$store.dispatch('user/setGeofenceVisible', { geofence, visible })
      const features = this.geofencesSource.features.filter((e) => e.properties.id === geofence.id)
      features.forEach(f => { f.properties.visible = visible })
    },
    getVisibilityIcon(geofence) {
      return geofence.visible ? 'fas fa-eye' : 'fas fa-eye-slash'
    },
    handleEdit(row) {
      serverBus.$emit(event.updateGeofence, row)
    },
    handleMove(row) {
      serverBus.$emit(event.movePOI, row)
    },
    handleDelete(row) {
      this.$confirm(this.$t('geofence.' + this.getType(row) + '_delete_info') + row.name + '?',
        this.$t('geofence.' + this.getType(row) + '_delete_title'), {
          confirmButtonText: this.$t('geofence.geofence_edit_confirm'),
          cancelButtonText: this.$t('geofence.geofence_edit_cancel')
        }).then(() => {
        traccar.deleteGeofence(row.id, this.geofenceDeleted)
      }).catch(() => {
      })
    },
    geofenceEdited: function(row) {
      this.$message({
        type: 'success',
        message: this.$t('geofence.' + this.getType(row) + '_edited')
      })
    },
    geofenceDeleted(geofenceId) {
      const geofence = vm.$store.state.user.geofences.find((e) => e && e.id !== geofenceId)
      vm.$store.state.user.geofences = vm.$store.state.user.geofences.filter((e) => e && e.id !== geofenceId)
      this.geofencesSource.features = this.geofencesSource.features.filter((e) => e.properties.id !== geofenceId)
      lnglat.refreshGeofences()
      this.$message({
        message: this.$t('geofence.' + this.getType(geofence) + '_deleted'),
        type: 'success',
        duration: 5 * 1000
      })
    },
    getType(row) {
      if (!row) { return 'poi' }
      return row.area.startsWith('POLYGON') ? 'geofence' : (row.area.startsWith('LINESTRING') ? 'linegeofence' : 'poi')
    }
  }
}
</script>

<style lang="scss" scoped>
  .header {
    padding: 10px;
  }
  .mobileScroll {
    -webkit-overflow-scrolling: touch;
  }
</style>
<style scoped>
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 5px;
  }
  .iconGeofence {
    padding-left:6px
  }
</style>
