<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="$t('settings.geofence_edit_title')"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
  >
    <span>{{ }}</span>
    <el-form ref="form" :rules="rules" :model="{name: name}">
      <div class="form-item-block">
        <div class="form-item-row">
          <el-form-item v-if="getType(selectedGeofence)==='poi'" class="form-item-block-left" :label="$t('Icon')">
            <img :src="'img/icons/pois/'+icon+'-blue.svg'" alt="" style="vertical-align:middle">
          </el-form-item>
          <el-form-item class="form-item-block-right" :label="$t('settings.geofence_form_name')" prop="name">
            <el-input v-model="name" />
          </el-form-item>
        </div>
      </div>
      <div class="form-item-block">
        <div class="form-item-row">
          <el-form-item :label="$t('settings.geofence_form_description')">
            <el-input v-model="description" />
          </el-form-item>
          <el-form-item class="form-item-block-right" :label="$t('settings.groups')" style="padding-left: 40px; width: auto">
            <el-select v-model="selectedGroups" multiple placeholder="Select" style="width: 100%">
              <el-option
                v-for="item in groups"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div v-if="getType(selectedGeofence)==='geofence' || getType(selectedGeofence)==='linegeofence'" class="form-item-block">
        <div class="form-item-row">
          <el-form-item class="form-item-block-right" :label="$t('settings.geofence_form_speedlimit')">
            <el-input-number v-model="speedLimit" :min="0" :precision="0" />
          </el-form-item>
        </div>
      </div>
      <div class="form-item-block">
        <div class="form-item-row">
          <el-form-item class="form-item-block-right" :label="$t('settings.geofence_form_distance')">
            <el-input-number v-model="distance" :min="0" :precision="0" />
          </el-form-item>
          <el-form-item v-if="mode!=='create'">
            <el-checkbox v-model="distanceToAll" style="padding-top: 40px" :label="$t('settings.geofence_form_distance_apply_to_all')" />
          </el-form-item>
        </div>
      </div>
      <div class="form-item-block">
        <div class="form-item-row">
          <el-form-item class="form-item-block-right" :label="$t('settings.geofence_form_color')">
            <el-color-picker v-model="color" :value="color"></el-color-picker>
          </el-form-item>
          <el-form-item v-if="getType(selectedGeofence)==='poi' && mode!=='create'">
            <el-checkbox v-model="colorToAll" style="padding-top: 40px" :label="$t('settings.geofence_form_color_apply_to_all')" />
          </el-form-item>
        </div>
      </div>
      <div class="form-item-block">
        <div class="form-item-row">
          <el-form-item v-if="getType(selectedGeofence)==='geofence'" :label="$t('Área')" class="form-item-block-left">
            <el-checkbox v-model="fill">{{ $t('Con relleno') }}</el-checkbox>
          </el-form-item>
          <el-form-item v-if="getType(selectedGeofence)==='poi'" class="form-item-block-right" :label="$t('settings.geofence_form_options')">
            <el-row>
              <el-col v-for="type in markerTypes" :key="type" :span="2">
                <el-tooltip :content="$t('settings.geofence_icon_'+type.replace('-', '_'))" placement="top">
                  <el-button
                    size="small"
                    style="border-style: none"
                    @click="handleChangeIcon(type)"
                  >
                    <img :src="getImageSrc(type)" alt=""></el-button>
                </el-tooltip>
              </el-col>
            </el-row>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="info"
        class="formButton"
        size="small"
        @click="$emit('close')"
      >{{ $t('settings.form_cancel') }}</el-button>
      <el-button
        type="success"
        class="formButton"
        size="small"
        :loading="loading"
        @click="handleSubmitForm"
      >{{ $t('settings.form_confirm') }}</el-button>
      <!--el-progress v-if="loading" :percentage="percentage" style="padding-top: 5px"></el-progress-->
    </span>
  </el-dialog>
</template>

<script>
import { traccar } from '@/api/traccar-api'
import { serverBus, vm } from '@/main'
import { mapGetters } from 'vuex'
import * as lnglat from '@/utils/lnglat'
import { processGeofence } from '@/utils/lnglat'
import { addToGroup, getGeofenceGroups, removeFromGroup } from '@/utils/group'
import { pinmeapi } from '@/api/pinme'

export default {
  name: 'CreateGeofence',
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'edit'
    },
    geofenceIcon: {
      type: String,
      default: ''
    },
    selectedGeofence: {
      type: Object,
      default: undefined
    },
    geofenceDistance: {
      type: Number,
      default: 100
    },
    applyColorToAll: {
      type: Boolean,
      default: false
    },
    geofenceColor: {
      type: String,
      default: ''
    },
    geofenceFill: {
      type: Boolean,
      default: false
    },
    applyDistanceToAll: {
      type: Boolean,
      default: false
    },
    geofenceSpeedLimit: {
      type: Number,
      default: 0
    },
    geofenceDescription: {
      type: String,
      default: ''
    },
    geofenceName: {
      type: String,
      default: ''
    },
    geofenceGroups: {
      type: Array,
      default: undefined
    }
  },
  data() {
    return {
      percentage: 0,
      dialogVisible: false,
      name: '',
      description: '',
      speedLimit: 0,
      icon: '',
      distance: 100,
      distanceToAll: false,
      colorToAll: false,
      color: '',
      fill: false,
      selectedGroups: [],
      rules: {
        name: [{ required: true, trigger: 'blur', message: this.$t('profile.user_name_required') }]
      }
    }
  },
  createPOI(value) {
    try {
      this.createGeofence(value)
    } catch (e) {
      this.$log.error(e) // on mobile version an exception is thrown
    }
  },
  computed: {
    ...mapGetters(['markerTypes', 'loading', 'geofences', 'groups'])
  },
  watch: {
    visible() {
      this.dialogVisible = this.visible
    },
    geofenceGroups() {
      this.selectedGroups = this.geofenceGroups
    },
    geofenceIcon() {
      this.icon = this.geofenceIcon
    },
    geofenceName() {
      this.name = this.geofenceName
    },
    geofenceDescription() {
      this.description = this.geofenceDescription
    },
    geofenceSpeedLimit() {
      this.speedLimit = this.geofenceSpeedLimit
    },
    geofenceDistance() {
      this.distance = this.geofenceDistance
    },
    applyDistanceToAll() {
      this.distanceToAll = this.applyDistanceToAll
    },
    geofenceFill() {
      this.fill = this.geofenceFill
    },
    geofenceColor() {
      this.color = this.geofenceColor
    },
    applyColorToAll() {
      this.colorToAll = this.applyColorToAll
    }
  },
  methods: {
    findFeatureById(id) {
      return vm.$static.geofencesSource.features.find(e => e.properties.id === id)
    },
    async geofenceEdited(row) {
      this.$store.commit('transient/SET_DATA_LOADED', false)
      const feature = this.findFeatureById(row.id)
      if (feature) {
        if (this.getType(row) === 'poi') {
          feature.properties.icon = row.attributes.icon
        }
        if (this.getType(row) === 'geofence') {
          feature.properties.fill = row.attributes.fill
        }
        feature.properties.color = row.attributes.color
        feature.properties.title = row.name
      }
      await processGeofence(row)
      this.$store.commit('user/SET_GEOFENCE', row)
      lnglat.refreshGeofences()
      this.$message({
        type: 'success',
        message: this.$t('geofence.' + this.getType(row) + '_edited')
      })
      this.$store.commit('transient/SET_DATA_LOADED', true)
    },
    updateAreaDistance(area) {
      return area.split(',')[0] + ', ' + this.distance + ')'
    },
    async handleSubmitForm() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          this.$store.commit('transient/SET_LOADING', true)
          try {
            let geofence = this.selectedGeofence
            geofence.name = this.name
            geofence.description = this.description
            if (this.getType(this.selectedGeofence) === 'poi') {
              geofence.area = this.updateAreaDistance(geofence.area)
              geofence.attributes = {
                icon: this.icon,
                color: this.color
              }
            } else if (this.getType(this.selectedGeofence) === 'geofence') {
              geofence.attributes = {
                speedLimit: this.speedLimit / 1.85200,
                color: this.color,
                fill: this.fill
              }
            } else {
              geofence.attributes = {
                speedLimit: this.speedLimit / 1.85200,
                color: this.color
              }
            }
            if (this.distanceToAll || this.colorToAll) {
              this.percentage = 0
              let count = 0
              const chunkSize = 4
              for (let i = 0; i < this.geofences.length; i += chunkSize) {
                await Promise.all(this.geofences.slice(i, i + chunkSize).map(g => {
                  if (this.distanceToAll && g.area.startsWith('CIRCLE')) {
                    g.area = this.updateAreaDistance(g.area)
                  }
                  if (this.colorToAll) {
                    g.attributes.color = this.color
                  }
                  return traccar.editGeofence(g.id, g)
                    .then(() => { this.$notify({ message: `OK ${g.name}`, type: 'success' }) })
                    .catch(e => this.$notify({ message: `${g.name} ${(e.response && e.response.data) || e.message}`, type: 'error' }))
                    .finally(() => { this.percentage = Math.round(count++ / this.geofences.length * 100) })
                }))
              }
            }
            if (this.mode === 'create') {
              geofence = await traccar.newGeofence(geofence.name, geofence.description, geofence.area, geofence.attributes).then(d => d.data)
              await processGeofence(geofence)
              this.$emit('created', geofence)
            } else {
              await traccar.editGeofence(this.selectedGeofence.id, geofence)
              await this.geofenceEdited(geofence)
            }
            await this.associateGroups(geofence)
          } catch (e) {
            this.$message({ type: 'error', message: (e.response && e.response.data) || e.message })
          }
          this.$store.commit('transient/SET_LOADING', false)
          this.$emit('close')
          if (this.mode === 'create') { this.dialogVisible = false }
        }
      })
    },
    handleChangeIcon(value) {
      this.icon = value
    },
    getImageSrc(imgType) {
      return './img/icons/pois/' + imgType + '-blue.svg'
    },
    getType() {
      if (this.selectedGeofence && this.selectedGeofence.area && this.selectedGeofence.area.startsWith('POLYGON')) { return 'geofence' }
      if (this.selectedGeofence && this.selectedGeofence.area && this.selectedGeofence.area.startsWith('LINE')) { return 'line' }
      return 'poi'
    },
    async createGeofence() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          try {
            const data = await traccar.newGeofence(this.form.name, this.form.desc, this.selectedGeofence).then(d => d.data)
            this.$emit('geofenceCreated', data)
          } catch (e) {
            serverBus.$emit('message', self.$t('map.' + this.getType() + '_create_error') + ': ' + e.message)
          }
          this.$emit('close')
        }
      })
    },
    async associateGroups(geofence) {
      const currentGroupIds = getGeofenceGroups(geofence, this.groups).map(g => g.id)
      const groupIdsToRemove = currentGroupIds.filter(x => !this.selectedGroups.includes(x))
      const groupIdsToAdd = this.selectedGroups.filter(x => !currentGroupIds.includes(x))
      const groupGeofencePermissionsToRemove = groupIdsToRemove.map(groupId => {
        const group = this.groups.find(g => g.id === groupId)
        removeFromGroup(geofence, group)
        return {
          groupId: groupId,
          geofenceId: geofence.id
        }
      })
      const groupGeofencePermissionsToAdd = groupIdsToAdd.map(groupId => {
        const group = this.groups.find(g => g.id === groupId)
        addToGroup(geofence, group)
        return {
          groupId: groupId,
          geofenceId: geofence.id
        }
      })

      await traccar.deleteAllPermissions(groupGeofencePermissionsToRemove)
      // https://www.traccar.org/forums/topic/geofences-in-groups-should-be-visible-to-users-directly-without-the-need-for-user-geofence-association/
      await pinmeapi.addAllPermissions(groupGeofencePermissionsToAdd)
    },
    handleClose(done) {
      this.$emit('close')
      done()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../styles/element-variables.scss';
.form-item-block {
  width: 100%;
  display: table;
  margin-bottom: 10px
}

.el-form-item {
  margin-bottom: 5px
}

.form-item-block {
  width: 100%;
  display: table;
  margin-bottom: 5px
}

.form-item-row {
  display: table-row;
}

.form-item-block-left{
  display: table-cell;
  width: 50px;
  padding-right: 10px;
  padding-bottom: 5px;
}
.form-item-block-right{
  width: 400px;
  display: table-cell;
  padding-bottom: 5px;
}
.iconGeofence {
  padding-left:6px
}
</style>
