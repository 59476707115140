import axios from 'axios'
import { feature, geometry } from '@turf/helpers'
import store from '../../../../store'

const ignored = ['CHIT_CHAT']
export default {
  layer: {
    id: 'WazeLayer',
    type: 'line',
    source: 'waze',
    paint: {
      'line-color': ['case',
        ['>', ['get', 'level'], 3], '#ff5252', '#ffa600'],
      'line-width': 8
    },
    layout: {
      'line-cap': 'round'
    }
  },
  alertsLayer: {
    id: 'WazeLayerAlerts',
    type: 'symbol',
    source: 'wazeAlerts',
    filter: ['!=', ['get', 'type'], 'JAM'],
    layout: {
      'icon-image': ['concat', 'waze|', ['get', 'type']],
      // 'text-field': ['get', 'type'],
      'icon-anchor': 'bottom'
    }
  },
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] }
  },
  alertsSource: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] }
  },
  async refresh(map) {
    if (!store.getters.showTraffic && !store.getters.showWaze) { return }
    try {
      const b = map.getBounds()
      const types = []
      if (store.getters.showTraffic) { types.push('traffic') }
      if (store.getters.showWaze) { types.push('alerts') }
      const url = `/waze?bottom=${b.getSouth()}&left=${b.getWest()}&right=${b.getEast()}&top=${b.getNorth()}&types=${types.join(',')}&env=row`
      const { data } = await axios.get(url)
      this.source.data.features = data.jams
        ? data.jams.map(j => feature(
          geometry('LineString', j.line.map(p => [p.x, p.y])),
          j
        )) : store.getters.showTraffic ? this.source.data.features : []
      map.getSource('waze') && map.getSource('waze').setData(this.source.data)
      this.alertsSource.data.features = data.alerts
        ? data.alerts.filter(j => ignored.indexOf(j.type) === -1).map(a =>
          feature(geometry('Point', [a.location.x, a.location.y]), a))
        : store.getters.showWaze ? this.alertsSource.data.features : []
      map.getSource('wazeAlerts') && map.getSource('wazeAlerts').setData(this.alertsSource.data)
    } catch (e) {
      console.error(e)
    }
  },
  svcIcons: {
    'police': 'police-987e7deeb8893a0e088fbf5aac5082f7.svg',
    'hazard': 'hazard-02e4ae89da4f6b5a88a7bc5e4725f2e5.svg',
    'road_closed': 'closure-d85b532570fa89f0cc951f5f3ef1e387.svg',
    'accident-major': 'accident-major-e4499a78307739ab9e04b2c57eb65feb.svg',
    'accident': 'accident-minor-8e99e127082262b1d3c4f81c90fb5cef.svg'
  }
}
