<template>
  <!--suppress HtmlUnknownAttribute -->
  <div style="width: 100%; height: 100%">
    <div id="map" ref="map" class="divMapGL" :style="heightMap"></div>
    <div v-if="userLoggedIn" id="historyMode" :style="heightHistoryPanel" class="historyPanel">
      <current-position-data v-if="historyMode" class="currentPositionData"></current-position-data>
      <div v-if="historyMode" style="height: 5px"></div>
      <history-panel v-if="historyMode" class="historyPanel"></history-panel>
    </div>
    <div
      id="showSearch"
      ref="search"
      class="mapboxgl-ctrl mapboxgl-ctrl-group"
    >
      <button
        :style="showSearch ? 'background-color: dimgray' : ''"
        class="mapboxgl-ctrl-icon"
        @click="clickShowSearch"
      >
        <i class="fas fa-search" style="color: #979797"></i>
      </button>
    </div>
    <div
      id="showDirections"
      ref="directions"
      class="mapboxgl-ctrl mapboxgl-ctrl-group"
    >
      <button
        v-if="drawMode !== 'draw_line_string'"
        :style="showDirections?'background-color: dimgray':''"
        class="mapboxgl-ctrl-icon"
        @click="showDirections = !showDirections"
      >
        <i class="fas fa-directions" style="color: #979797"></i>
      </button>
    </div>
    <div
      v-show="showSearch"
      id="searchBox"
      ref="searchBox"
      class="mapboxgl-ctrl mapboxgl-ctrl-group"
    >
      <input
        v-show="showSearch"
        ref="searchBoxInput"
        v-model="search"
        class="searchInput"
        type="search"
        @keyup.enter="onEnter"
      >
    </div>
    <div
      id="traffic"
      ref="traffic"
      class="mapboxgl-ctrl mapboxgl-ctrl-group"
    >
      <button
        :title="$t('Traffic')"
        class="mapboxgl-ctrl-icon mapboxgl-ctrl-traffic"
        :style="showTraffic?'background-color: dimgray':''"
        @click="$store.commit('map/SET_SHOW_TRAFFIC', !showTraffic)"
      ></button>
      <button
        title="Waze"
        class="mapboxgl-ctrl-icon mapboxgl-ctrl-waze"
        :style="showWaze?'background-color: dimgray':''"
        @click="$store.commit('map/SET_SHOW_WAZE', !showWaze)"
      ></button>
      <button
        class="mapboxgl-ctrl-icon mapboxgl-ctrl-signs"
        :title="$t('Signs')"
        :style="showSigns?'background-color: dimgray':''"
        @click="TOGGLE_SIGNS"
      ></button>
    </div>
    <div
      id="profile"
      ref="profile"
      class="mapboxgl-ctrl"
    >
      <avatar></avatar>
    </div>
    <geofence-form
      :geofence-color="geofenceColor"
      :geofence-description="geofenceDescription"
      :geofence-icon="geofenceIcon"
      :geofence-name="geofenceName"
      :geofence-speed-limit="geofenceSpeedLimit"
      :geofence-distance="geofenceDistance"
      :geofence-groups="geofenceGroups"
      :mode="geofenceMode"
      :visible="geofence !== undefined"
      :selected-geofence="geofence"
      @close="errorCreating"
      @created="createGeofenceFeature"
    />
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { serverBus, vm } from '@/main'
import settings from '../../settings'
import * as lnglat from '../../utils/lnglat'
import { MapboxCustomControl } from '@/utils/lnglat'
import Vue from 'vue'
import { traccar } from '@/api/traccar-api'
import HistoryPanel from './HistoryPanel'
import i18n, { getLanguage } from '../../lang'
import StyleSwitcherControl from './mapbox/styleswitcher/StyleSwitcherControl'
import CurrentPositionData from './CurrentPositionData'
import { checkForUpdates } from '@/utils/utils'
import * as consts from '../../utils/consts'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PoiPopUp from './PoiPopUp'
import * as event from '../../events'
import { animate } from '@/utils/animation'
import geofencesLayer from './mapbox/layers/GeofencesLayer'
import eventsLayer from './mapbox/layers/EventsLayer'
import layerManager from './mapbox/LayerManager'
import vehiclesLayer from './mapbox/VehiclesLayer'
import VehicleDetail from '@/views/map/VehicleDetail'
import store from '@/store'
import { popUps, getGeofenceType, getGeofenceDistance } from '@/utils/lnglat'
import { hexToRgb } from '@/utils/images'
import { checkFuelThresholds } from '@/utils/device'
import { getServerHost } from '@/api'
import * as notifications from '../../utils/notifications'
import * as alertType from '@/alerts/alertType'
import { newEventReceived } from '@/events'
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions'
import { pinmeapi } from '@/api/pinme'
import routeLayers from './mapbox/layers/RouteLayers'
import { getPartnerData } from 'fleetmap-partners'
import { partnerData } from '@/utils/partner'
import { Loader } from '@googlemaps/js-api-loader'
import WazeLayer from '@/views/map/mapbox/layers/WazeLayer'
import { getGeofencesGeoJson } from '@/utils/lnglat'
import Avatar from '@/components/Avatar'
import breakpoints from '@/utils/breakpoints'
import GeofenceForm from '@/components/GeofenceForm.vue'
import { getGeofenceGroups } from '@/utils/group'
import { cdnPoiImages } from '@/utils/consts'

let socketReconnect = 0
const historyPanelHeight = 255 + (window.innerWidth < breakpoints.xl ? 20 : 0)

function getSocketUrl() {
  const hostName = process.env.WEB_SOCKET_HOST || getServerHost() || 'api.pinme.io'
  Vue.$log.debug('websocket ', hostName)
  return `wss://${hostName}/api/socket`
}

const directionsOptions = {
  accessToken: consts.mapboxAccessToken,
  unit: 'metric',
  language: getLanguage().slice(0, 2),
  controls: {
    profileSwitcher: false,
    inputs: true
  },
  interactive: true,
  placeholderDestination: vm.$i18n.t('Destino'),
  placeholderOrigin: vm.$i18n.t('Origen'),
  alternatives: true,
  congestion: true
}

let directionsControl = null
const wayPointsMarkers = []

export default {
  name: 'VueMap',
  components: { GeofenceForm, CurrentPositionData, HistoryPanel, Avatar },
  data() {
    return {
      geofenceName: '',
      geofenceDescription: '',
      geofenceGroups: [],
      geofenceSpeedLimit: 0,
      geofenceColor: '',
      geofenceIcon: '',
      geofenceFill: false,
      geofenceDistance: 100,
      geofenceMode: 'create',
      geofence: undefined,
      search: '',
      drawMode: '',
      accessToken: consts.mapboxAccessToken,
      origin: [-9.267959, 38.720023],
      destination: [],
      animating: true,
      unsubscribe: null,
      parentHeight: 0,
      imageDownloadQueue: [],
      loadingCount: 0,
      showDirections: false,
      showSearch: false,
      directionsIndex: 0,
      marker: null,
      selectedPOI: null
    }
  },
  computed: {
    ...mapGetters(['initialized',
      'followVehicle', 'historyMode', 'dataLoaded', 'name', 'geofences', 'events', 'drivers',
      'showLabels', 'isPlaying', 'vehicles3dEnabled', 'deviceById', 'deviceByName',
      'loading', 'zoom', 'center', 'mapType', 'mapStyle', 'devices', 'alerts', 'showTraffic', 'showWaze',
      'showSigns', 'groups', 'user'
    ]),
    userLoggedIn() {
      return this.name !== ''
    },
    heightMap() {
      return this.historyMode ? 'height: calc(100% - ' + historyPanelHeight + 'px)' : 'height:100%'
    },
    heightHistoryPanel() {
      return this.historyMode ? 'height: ' + historyPanelHeight + 'px' : 'height:0'
    },
    popUps: {
      get: function() {
        return lnglat.popUps
      }
    },
    eventPopUps: {
      get: function() {
        return lnglat.eventPopUps
      }
    },
    isMobile() { return false },
    positionsSource() { return this.$root.$static.positionsSource },
    geofencesSource() { return this.$root.$static.geofencesSource },
    eventsSource() { return this.$root.$static.eventsSource },
    positions() {
      return this.positionsWebsocket
    },
    pois() {
      return this.geofences.filter(g => g && g.area.startsWith('CIRCLE'))
    },
    map() {
      return vm.$static.map
    },
    selected: {
      get: function() {
        return vm.$data.currentDevice
      },
      set: function(value) {
        this.$log.debug('currentDevice, ', value)
        vm.$data.currentDevice = value
      }
    }
  },
  watch: {
    devices() {
      this.initData()
    },
    historyMode(value) {
      if (value) { window.socket.close() } else { this.connectSocket() }
    },
    showWaze() { this.toggleWaze() },
    showTraffic() { this.toggleWaze() },
    '$route'(to) {
      if (to.name === 'Map') {
        setTimeout(() => serverBus.$emit(event.mapShow), 500)
      }
    },
    showDirections() {
      this.$log.debug(this.showDirections)
      this.directionsIndex = 0
      wayPointsMarkers.forEach(m => m.remove())
      wayPointsMarkers.length = 0
      if (this.showDirections) {
        directionsControl = new MapboxDirections(directionsOptions)
        this.map.addControl(directionsControl, 'top-right')
      } else {
        this.map.removeControl(directionsControl)
      }
    }
  },
  created() {
    this.$log.info('VueMap', this.userLoggedIn)
    this.setLoading(true)
  },
  static() {
    return {
      map: vm.$static.map,
      marker: new mapboxgl.Marker({
        draggable: true
      }),
      draw: null
    }
  },
  beforeDestroy() {
    Vue.$log.warn('VueMap beforeDestroy')
    this.unsubscribeEvents()
  },
  mounted() {
    this.$log.debug('VueMap')
    this.parentHeight = this.$parent.$el.clientHeight
    mapboxgl.accessToken = this.accessToken
    this.checkStyle()
    this.$root.$static.map = new mapboxgl.Map({
      container: 'map',
      style: this.mapStyle,
      attributionControl: false,
      preserveDrawingBuffer: true
    })
    this.setZoomAndCenter()
    this.subscribeEvents()
    this.searchControl = { onAdd: () => this.$refs.searchBox, onRemove: () => {} }
    this.initGoogleMaps()
    this.$static.marker.getElement().addEventListener('click', () => {
      this.$confirm(this.$t('map.confirm_move_poi'), this.$t('map.move_poi_title') + ' ' + this.selectedPOI.name, {
        confirmButtonText: this.$t('map.create_confirm'),
        cancelButtonText: this.$t('map.create_cancel')
      }).then(() => {
        this.updatePOI()
      }).catch(() => {
        this.$static.marker.remove()
      })
    })
  },
  timers: {
    checkUpdates: { time: 120000, autostart: true, repeat: true },
    setTime: { time: 5000, autostart: true, repeat: true }
  },
  methods: {
    ...mapMutations('map', ['setCenter', 'setZoom', 'TOGGLE_SIGNS']),
    ...mapActions('transient', ['setLoading']),
    checkStyle() {
      try {
        if (
          this.mapStyle.sources &&
          this.mapStyle.sources['raster-tiles'].tiles[0].includes('google') &&
          !getPartnerData().googleMap) {
          this.$store.dispatch('map/setStyle', 'mapbox://styles/mapbox/streets-v12')
        }
      } catch (e) {
        console.error(e)
      }
    },
    onEnter() {
      const matches = this.search.match(
        /^ *(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*) *$/i)
      if (matches) {
        const coord1 = Number(matches[1])
        const coord2 = Number(matches[2])

        if (coord1 < -90 || coord1 > 90) {
          this.centerSearchResult([coord1, coord2])
        } else if (coord2 < -90 || coord2 > 90) {
          // must be lat, lng
          this.centerSearchResult([coord2, coord1])
        } else {
          this.centerSearchResult([coord2, coord1])
        }
      }
    },
    toggleWaze() {
      const show = this.showWaze || this.showTraffic
      layerManager.addRemoveWazeLayer(show)
      if (show) { WazeLayer.refresh(this.map) }
    },
    centerSearchResult(center) {
      if (this.searchMarker) {
        this.searchMarker.remove()
      }
      this.searchMarker = new mapboxgl.Marker()
        .setLngLat(center)
        .addTo(this.$static.map)
      this.$static.map.flyTo({
        center,
        zoom: Math.max(13, this.$static.map.getZoom())
      })
    },
    onPlaceChanged() {
      const place = this.autoComplete.getPlace()
      if (place && place.geometry) {
        const center = { lng: place.geometry.location.lng(), lat: place.geometry.location.lat() }
        this.centerSearchResult(center)
      }
    },
    initGoogleMaps() {
      new Loader({
        apiKey: process.env.googleKey, libraries: ['places']
      }).load().then(() => {
        // eslint-disable-next-line no-undef
        this.autoComplete = new google.maps.places.Autocomplete(this.$refs.searchBoxInput)
        this.autoComplete.addListener('place_changed', this.onPlaceChanged)
      })
    },
    clickShowSearch() {
      this.showSearch = !this.showSearch
      if (this.showSearch) {
        this.map.addControl(this.searchControl, 'top-right')
      } else {
        if (this.searchMarker) {
          this.searchMarker.remove()
        }
        this.map.removeControl(this.searchControl)
      }
    },
    connectSocket() {
      if (this.$store.state.socket.isConnected) { return }
      delete window.socket
      const socket = new WebSocket(getSocketUrl())
      this.ensureToken()
      window.socket = socket
      const events = ['onclose', 'onerror', 'onopen']
      events.forEach((eventType) => {
        socket[eventType] = (event) => {
          const mutation = 'SOCKET_ON' + event.type.toUpperCase()
          this.$store.commit(mutation, socket)
          if (event.type === 'close' && !this.historyMode) {
            this.$log.warn('socket closed!')
            traccar.positions().then(d => d.data)
              .then(positions => this.updateMarkers(positions.sort((a, b) => a.fixTime === b.fixTime ? 0 : a.fixTime < b.fixTime ? -1 : 1)))
            setTimeout(() => {
              this.connectSocket()
              this.$store.commit('SOCKET_RECONNECT', socketReconnect++)
            }, 10000)
          }
        }
      })
      socket['onmessage'] = async(event) => {
        if (socketReconnect > 0) {
          socketReconnect = 0
        }
        const data = JSON.parse(event.data)
        if (data.positions) {
          this.checkCommandResults(data.positions)
          this.updateMarkers(data.positions.sort((a, b) => a.fixTime === b.fixTime ? 0 : a.fixTime < b.fixTime ? -1 : 1))
        }
        if (data.events) {
          Vue.$log.debug('received', data.events[0])
          const filtered = []
          for (const e of data.events) {
            if (await notifications.filterEvent(e, this.alerts)) { filtered.push(e) } else { Vue.$log.debug('ignoring event', e) }
          }
          const events = notifications.convertEvents(filtered, true)
          for (let i = 0; i < events.length; i++) {
            const event = events[i]
            if (event.type === alertType.alarmSOS) {
              if (event.device) { event.device.alarmSOSReceived = true }
              try {
                await new Audio('/sound/alarm.mp3').play()
              } catch (e) {
                Vue.$log.error(e)
              }
            }
            serverBus.$emit(newEventReceived, event)
          }
        }
      }
    },
    checkCommandResults(positions) {
      positions.forEach(p => {
        if (p.attributes && p.attributes.result) {
          const device = this.devices.find(d => d.id === p.deviceId)
          if (device && device.attributes && device.attributes.internalNotes === 'Camera') {
            this.$notify({
              title: device && device.name,
              message: p.attributes.result,
              type: 'info',
              duration: 0
            })
          }
        }
      })
    },
    shouldAnimate(feature, position) {
      return this.devices.length < settings.maxMarkersForAnimation &&
        settings.animateMarkers &&
        !this.loading &&
        !this.historyMode &&
        this.loadingCount >= 3 &&
        position.attributes.ignition &&
        this.initialized &&
        lnglat.contains(
          this.map.getBounds(),
          { longitude: feature.geometry.coordinates[0], latitude: feature.geometry.coordinates[1] }) &&
        this.map.getZoom() >= consts.detailedZoom
    },
    setTime() {
      if (this.loadingCount !== -1 && this.loadingCount < 1) {
        console.warn('timer ignored, waiting loadingCount', this.loadingCount)
        return
      }
      if (!this.historyMode) {
        this.$store.dispatch('setTime')
        // this will update VehicleTable
        this.$store.dispatch('user/refreshDevices')
        layerManager.refreshLayers()
      }
    },
    checkUpdates() {
      checkForUpdates()
    },
    async initData() {
      // init only once
      if (this.initialized) {
        return
      }
      try {
        const map = this.$static.map
        map.loadImage(consts.cdnUrl + '/images/stop-sign.png', function(error, image) {
          if (error) {
            throw error
          }
          if (!map.hasImage('stop-sign')) {
            map.addImage('stop-sign', image)
          }
        })
        const { data } = await traccar.positions()
        const devicesIgnitionOffDate = await this.getDevicesIgnitionOffDate()
        this.processPositions(data, devicesIgnitionOffDate)
        Vue.$log.debug('finishLoading')
        this.finishLoading()
        this.connectSocket()
      } catch (e) {
        this.$log.error(e)
        this.$message.error(e.message)
      }
      this.$store.commit('transient/SET_INITIALIZED', true)
    },
    initGeofences() {
      this.geofencesSource.features = getGeofencesGeoJson(this.geofences)
      this.refreshGeofences()
    },
    finishLoading() {
      // load layers, load map and load data
      if (++this.loadingCount === 3) {
        this.$log.debug(this.loadingCount)
        this.setLoading(false)
        if (this.$route.query.vehicleName && this.$route.query.date) {
          const device = this.deviceByName(this.$route.query.vehicleName)
          if (device) {
            serverBus.$emit(event.deviceSelectedOnMap, device)
            this.deviceSelected(device)
            this.$store.dispatch('transient/toggleHistoryMode')
          }
        }
        layerManager.refreshLayers()
      } else {
        this.$log.info('not finishing loading', this.loadingCount)
      }
    },
    mapResize() {
      if (this.map) {
        this.map.resize()
        this.map.triggerRepaint()
      } else {
        this.$log.error('mapResize received but theres no map instance: ', this.map)
      }
    },
    onMapLoad() {
      this.addControls()
      this.map.resize()
      if (this.userLoggedIn && this.devices.length) {
        this.initData()
      } else {
        this.$log.info('waiting for watcher, userLoggedIn', this.userLoggedIn, 'devices', this.devices)
      }
      this.$log.debug('finishLoading')
      this.finishLoading()
    },
    filterLayers(worldview) {
      try {
        // The "admin-0-boundary-disputed" layer shows boundaries
        // at this level that are known to be disputed.
        const map = this.map
        map.setFilter('admin-0-boundary-disputed', [
          'all',
          ['==', ['get', 'disputed'], 'true'],
          ['==', ['get', 'admin_level'], 0],
          ['==', ['get', 'maritime'], 'false'],
          ['match', ['get', 'worldview'], ['all', worldview], true, false]
        ])
        if (map.getLayer('admin-0-boundary')) {
          // The "admin-0-boundary" layer shows all boundaries at
          // this level that are not disputed.
          map.setFilter('admin-0-boundary', [
            'all',
            ['==', ['get', 'admin_level'], 0],
            ['==', ['get', 'disputed'], 'false'],
            ['==', ['get', 'maritime'], 'false'],
            ['match', ['get', 'worldview'], ['all', worldview], true, false]
          ])
          // The "admin-0-boundary-bg" layer helps features in both
          // "admin-0-boundary" and "admin-0-boundary-disputed" stand
          // out visually.
          map.setFilter('admin-0-boundary-bg', [
            'all',
            ['==', ['get', 'admin_level'], 0],
            ['==', ['get', 'maritime'], 'false'],
            ['match', ['get', 'worldview'], ['all', worldview], true, false]
          ])
        }

        map.setFilter('country-label', [
          'all',
          ['match', ['get', 'worldview'], ['all', worldview], true, false]
        ])
      } catch (e) {
        console.error(e)
      }
    },
    findFeatureByDeviceId(deviceId) {
      return lnglat.findFeatureByDeviceId(deviceId)
    },
    deviceChanged(device) {
      this.$log.debug('VueMap deviceChanged')
      const feature = this.findFeatureByDeviceId(device.id)
      if (feature && feature.properties.category !== device.category) {
        feature.properties.category = this.getCategory(device.category)
        layerManager.refreshLayers()
      }
    },
    deviceSelected(device) {
      this.$store.dispatch('map/followVehicle', null)
      this.$log.debug('VueMap deviceSelected')
      this.selected = device
      if (device.id) {
        const feature = this.findFeatureByDeviceId(device.id)
        if (feature) {
          if (!lnglat.contains(this.$static.map.getBounds(), {
            latitude: feature.geometry.coordinates[1],
            longitude: feature.geometry.coordinates[0]
          }) || this.$static.map.getZoom() < 10) {
            this.popUps.forEach(function(v, i, a) {
              if (a[i] && i !== device.id) { a[i].remove() }
            })
            this.flyToDevice(feature, device)
          } else { this.showPopup(feature, device) }
          vm.$static.currentFeature = feature
          this.showCurrentRoute()
        }
      }
    },
    showCurrentRoute() {
      if (this.selected.currentTrip) {
        layerManager.updateCurrentRouteSource(this.selected.currentTrip)
        if (!vm.$static.map.getLayer('current-route')) {
          vm.$static.map.addLayer(routeLayers.currentRouteLayer('current-route'))
        }
      }
    },
    eventsLoaded: function() {
      // this.eventsSource.features = this.processEvents(this.events)
      this.refreshEvents()
    },
    updateGeofence(row) {
      this.geofence = row
      this.geofenceMode = 'edit'
      this.selectedGeofence = row
      this.geofenceName = row.name
      this.geofenceDescription = row.description
      this.geofenceGroups = getGeofenceGroups(row, this.groups).map(g => g.id)
      this.geofenceSpeedLimit = row.attributes.speedLimit ? Math.round(row.attributes.speedLimit * 1.85200) : 0
      this.geofenceIcon = row.attributes.icon ? row.attributes.icon : 'marker'
      this.geofenceColor = row.attributes.color ? row.attributes.color : '#3232b4'
      this.geofenceFill = row.attributes.fill != null ? row.attributes.fill : true
      this.geofenceDistance = getGeofenceType(this.selectedGeofence) === 'poi' ? getGeofenceDistance(this.selectedGeofence) : 0
    },
    movePOI: function(poi) {
      this.poiPoppup && this.poiPoppup.remove()
      this.$message({ type: 'info', message: this.$t('map.poi_move_on_map'), duration: 10 * 1000 })
      this.selectedPOI = poi
      const str = poi.area.substring('CIRCLE ('.length, poi.area.indexOf(','))
      const coord = str.trim().split(' ')
      this.$static.marker
        .setLngLat([parseFloat(coord[1]), parseFloat(coord[0])])
        .addTo(vm.$static.map)
    },
    async updatePOI() {
      try {
        const poi = this.selectedPOI
        const radius = this.selectedPOI.area.split(',')[1].trim()
        poi.area = 'CIRCLE (' + this.$static.marker._lngLat.lat + ' ' + this.$static.marker._lngLat.lng + `, ${radius.substring(0, radius.indexOf(')'))})`
        await traccar.editGeofence(poi.id, poi)
        this.$static.marker.remove()
        vm.$store.state.user.geofences = vm.$store.state.user.geofences.filter((e) => e && e.id !== poi.id)
        this.geofencesSource.features = this.geofencesSource.features.filter((e) => e.properties.id !== poi.id)

        vm.$store.state.user.geofences.push(poi)
        const featureGeojson = geofencesLayer.getFeatureGeojson(poi)
        if (featureGeojson.properties.icon) {
          this.geofencesSource.features.push(geofencesLayer.getFeatureCircleGeojson(poi))
        }
        this.geofencesSource.features.push(featureGeojson)
        this.refreshGeofences()
      } catch (e) {
        await this.$alert((e.response && e.response.data) || e.message)
      }
    },
    eventSelected: async function(event) {
      const featureSelected = eventsLayer.findFeatureSelected()
      if (featureSelected !== undefined) {
        featureSelected.properties.selected = false
        this.eventPopUps[0].remove()
        this.eventPopUps.splice(0)
      }
      let feature
      let pos
      const device = this.devices.find(d => d.id === event.deviceId)
      // search device last position
      if (device && device.position && device.position.id === event.positionId) {
        pos = device.position
      } else {
        // search device route
        pos = device.route && device.route.filter(p => p.id === event.positionId)[0]
        if (!pos) {
          try {
            pos = await traccar.position(event.positionId, event.deviceId).then(d => d.data[0])
          } catch (e) {
            Vue.$log.error(e)
          }
        }
      }
      if (pos) {
        feature = eventsLayer.getFeatureGeojson(event, pos)
        this.eventsSource.features.push(feature)
        feature.properties.selected = true

        this.refreshEvents()
        this.showEventPopUp(feature)
        this.flyToFeature(feature)
      }
    },
    showEventPopUp(feature) {
      const popup = new mapboxgl.Popup({
        offset: [0, -20]
      })
      lnglat.showEventPopup(feature, popup, this.eventPopupOnClose)
    },
    eventPopupOnClose() {
      const featureSelected = eventsLayer.findFeatureSelected()
      if (featureSelected !== undefined) {
        featureSelected.properties.selected = false
      }
      this.refreshEvents()
    },
    areaSelected: function(object) {
      const feature = geofencesLayer.findFeatureById(object.id)
      if (feature) {
        this.flyToFeature(feature)
      }
    },
    showPopup(feature = this.$static.currentFeature, device = this.deviceSelected) {
      const coordinates = feature.geometry.coordinates.slice()
      const description = feature.properties.description
      lnglat.popUps.forEach(p => p.remove())
      lnglat.popUps[device.id] = new mapboxgl.Popup({ class: 'card2', offset: 25 })
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(vm.$static.map)
        .on('close', () => {
          Vue.$log.debug('popup closed', device.name)
          popUps[device.id].closed = true
        })
      if (this.lastPopup) {
        this.lastPopup.$destroy()
      }
      const VD = Vue.extend(VehicleDetail)
      this.lastPopup = new VD({
        i18n: i18n,
        data: {
          device: device,
          feature: feature
        },
        store: store
      })
      this.lastPopup.$mount('#vue-vehicle-popup')
    },
    flyToDevice(feature) {
      if (feature) {
        if (this.devices.length < settings.maxDevicesForAlerts) {
          this.$static.map.flyTo({
            center: { lng: feature.geometry.coordinates[0], lat: feature.geometry.coordinates[1] },
            zoom: consts.detailedZoom,
            maxDuration: 5000
          })
        } else {
          this.$static.map.jumpTo({
            center: { lng: feature.geometry.coordinates[0], lat: feature.geometry.coordinates[1] },
            zoom: consts.detailedZoom
          })
        }
        this.showPopup(feature, this.selected)
        // big hammer. moveEnd is not fired when there's no animation... I think this is a bug...
        setTimeout(layerManager.refreshLayers, 1000)
      }
    },
    flyToFeature: function(feature) {
      if (feature) {
        if (feature.geometry.type === 'Point') {
          this.$static.map.flyTo({
            center: { lng: feature.geometry.coordinates[0], lat: feature.geometry.coordinates[1] },
            zoom: 13
          })
        } else if (feature.geometry.type === 'LineString') {
          this.$static.map.fitBounds(lnglat.getBounds(feature.geometry.coordinates), {
            padding: 50
          })
        } else {
          this.$static.map.fitBounds(lnglat.getBounds(feature.geometry.coordinates[0]), {
            padding: 50
          })
        }
      }
    },
    refreshGeofences() {
      // Geofences ... POIs ... Lines
      if (vm.$static.map && vm.$static.map.getSource('geofences')) {
        vm.$static.map.getSource('geofences').setData(vm.$static.geofencesSource)
      }
    },
    refreshEvents() {
      // Events
      if (vm.$static.map && vm.$static.map.getSource('events')) {
        vm.$static.map.getSource('events').setData(vm.$static.eventsSource)
      }
    },
    setZoomAndCenter() {
      try {
        this.$static.map.setZoom(this.zoom)
        this.$static.map.setCenter(this.center)
      } catch (e) {
        // don't log it is killing sentry (apparently)
        // this.$log.error(e)
      }
    },
    addControls() {
      const map = this.$static.map
      this.$log.debug('adding mapcontrols...')
      map.addControl(new mapboxgl.NavigationControl(), 'top-left')
      map.addControl({ onAdd: () => this.$refs.search }, 'top-left')
      map.addControl({ onAdd: () => this.$refs.directions }, 'top-left')
      map.addControl({ onAdd: () => this.$refs.profile }, 'top-right')
      this.$static.draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          point: true,
          line_string: true,
          polygon: true,
          trash: false
        },
        touchEnabled: false
      })
      map.addControl(this.$static.draw, 'bottom-left')
      map.addControl({ onAdd: () => this.$refs.traffic }, 'bottom-left')
      map.addControl(new MapboxCustomControl('style-switcher-div'), 'bottom-left')
      const VD = Vue.extend(StyleSwitcherControl)
      const _vm = new VD({ i18n: i18n, store: store })
      _vm.$mount('#style-switcher-div')
      map.addControl(new mapboxgl.FullscreenControl(), 'bottom-left')
    },
    onMoveEnd() {
      if (!this.isPlaying) {
        this.setCenter(this.$static.map.getCenter())
        this.setZoom(this.$static.map.getZoom())
        if (this.loadingCount > 1) {
          layerManager.refreshLayers()
          WazeLayer.refresh(this.map)
        }
      } else {
        Vue.$log.debug('ignoring moveend', this.isPlaying)
      }
    },
    subscribeEvents() {
      const self = this
      this.$static.map.on('load', this.onMapLoad)
      this.$static.map.on('style.load', this.onStyleLoad)
      this.$static.map.on('move', this.onMove)
      this.$static.map.on('zoom', this.onMove)
      this.$static.map.on('moveend', this.onMoveEnd)

      this.$static.map.on('touchstart', 'pois', this.onClickTouchPois)
      this.$static.map.on('touchstart', vehiclesLayer.id, layerManager.onClickTouchUnclustered)

      this.$static.map.on('click', 'pois', this.onClickTouchPois)
      this.$static.map.on('click', 'signs', this.onClickTouchPois)
      this.$static.map.on('click', vehiclesLayer.id, layerManager.onClickTouchUnclustered)

      this.$static.map.on('mouseenter', 'pois', this.mouseEnter)
      this.$static.map.on('mouseenter', vehiclesLayer.id, this.mouseEnter)

      this.$static.map.on('mouseleave', 'pois', this.mouseLeave)
      this.$static.map.on('mouseleave', vehiclesLayer.id, this.mouseLeave)

      this.$static.map.on('draw.create', this.drawCreate)
      this.$static.map.on('draw.delete', this.drawDelete)
      this.$static.map.on('draw.update', this.drawUpdate)
      this.$static.map.on('draw.modechange', this.drawModeChange)
      this.$static.map.on('data', this.onData)
      this.$static.map.on('styleimagemissing', this.styleImageMissing)

      serverBus.$on(event.dataLoaded, this.initGeofences)
      this.$log.info('subscribed devicesLoaded')
      serverBus.$on(event.mapShow, this.mapResize)
      serverBus.$on(event.deviceSelected, this.deviceSelected)
      serverBus.$on(event.areaSelected, this.areaSelected)
      serverBus.$on(event.deviceChanged, this.deviceChanged)
      serverBus.$on(event.eventSelected, this.eventSelected)
      serverBus.$on(event.eventsLoaded, this.eventsLoaded)
      serverBus.$on(event.movePOI, this.movePOI)
      serverBus.$on(event.updateGeofence, this.updateGeofence)

      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'map/TOGGLE_TABLE_COLLAPSED':
            setTimeout(self.mapResize, 500)
            break
          case 'map/FOLLOW_VEHICLE':
            if (state.map.followVehicle) {
              const feature = lnglat.findFeatureByDeviceId(state.map.followVehicle.id)
              self.centerVehicle(feature)
              this.showPopup(feature, state.map.followVehicle)
            }
            break
          case 'settings/SET_SHOW_LABELS':
          case 'transient/TOGGLE_HISTORY_MODE':
            layerManager.refreshLayers()
            break
          default:
        }
      })
      window.addEventListener('resize', this.mapResize)
    },
    unsubscribeEvents() {
      this.$static.map.off('load', this.onMapLoad)
      this.$static.map.off('touchstart', vehiclesLayer, this.onTouchUnclustered)
      this.$static.map.off('click', vehiclesLayer, this.onClickTouchUnclustered)
      this.$static.map.off('style.load', this.onStyleLoad)
      this.$static.map.off('move', this.onMove)
      this.$static.map.off('moveend', this.onMoveEnd)
      this.$static.map.off('mouseenter', vehiclesLayer, this.mouseEnter)
      this.$static.map.off('mouseleave', vehiclesLayer, this.mouseLeave)
      this.$static.map.off('touchstart', 'pois', this.onClickTouchPois)
      this.$static.map.off('click', 'pois', this.onClickTouchPois)
      this.$static.map.off('click', 'signs', this.onClickTouchPois)
      this.$static.map.off('mouseenter', 'pois', this.mouseEnter)
      this.$static.map.off('mouseleave', 'pois', this.mouseLeave)
      this.$static.map.off('draw.create', this.drawCreate)
      this.$static.map.off('draw.delete', this.drawDelete)
      this.$static.map.off('draw.update', this.drawUpdate)
      this.$static.map.off('draw.modechange', this.drawModeChange)
      this.$static.map.off('data', this.onData)
      serverBus.$off(event.deviceChanged, this.deviceChanged)
      serverBus.$off(event.deviceSelected, this.deviceSelected)
      serverBus.$off(event.areaSelected, this.areaSelected)
      serverBus.$off(event.eventSelected, this.eventSelected)
      serverBus.$off(event.dataLoaded, this.initGeofences)
      serverBus.$off(event.mapShow, this.mapResize)
      serverBus.$off(event.eventsLoaded, this.eventsLoaded)
      serverBus.$off(event.movePOI, this.movePOI)
      if (this.unsubscribe) { this.unsubscribe() }
      window.removeEventListener('resize', this.mapResize)
    },
    centerVehicle(feature = this.$static.currentFeature) {
      lnglat.centerVehicle(feature)
    },
    mouseEnter() {
      this.map.getCanvas().style.cursor = 'pointer'
    },
    mouseLeave() {
      this.map.getCanvas().style.cursor = ''
    },
    onStyleLoad(e) {
      this.$log.debug('onStyleLoad ', e)
      const style = this.map.getStyle()
      if (style.sprite !== consts.spriteUrl) {
        this.$log.debug('setting sprite')
        style.sprite = consts.spriteUrl
        this.map.setStyle(style)
      } else {
        this.$log.debug('adding layers...')
        layerManager.addLayers(vm.$static.map)
        this.$log.debug('finishLoading')
        this.finishLoading()
      }
      if (partnerData() && partnerData().region) {
        setTimeout(() => this.filterLayers(partnerData().region), 1000)
      }
    },
    onData(e) {
      // if (!e.isSourceLoaded) return
      // layerManager.refreshLayers()
    },
    onTouchUnclustered: function(e) {
      this.$log.debug('touchUnclustered', e)
      this.onClickTouchUnclustered(e)
    },
    updateMarkers(positions) {
      if (!positions) {
        this.$log.warn('updateMarkers canceled, positions is undefined')
        return
      }
      this.processPositions(positions)
    },
    getCategory(category) {
      if (!category) { return 'default' }
      switch (category) {
        case 'motorcycle':
        case 'bicycle':
          return 'moto'
        case 'helicopter':
        case 'person':
          return 'arrow'
        case '':
        case 'car':
          return 'default'
        default:
          return category
      }
    },
    positionToFeature(position, device) {
      const labelColor = device.attributes.routeColor || '#000000'
      const feature = {
        type: 'Feature',
        properties: {
          text: device.name,
          deviceId: position.deviceId,
          category: this.getCategory(device.category),
          description: '<div id=\'vue-vehicle-popup\'></div>',
          bearing: this.map.getBearing(),
          animating: false,
          labelColor,
          labelColorR: parseInt(labelColor.slice(1, 3), 16),
          labelColorG: parseInt(labelColor.slice(3, 5), 16),
          labelColorB: parseInt(labelColor.slice(5, 7), 16)
        },
        geometry: {
          'type': 'Point',
          'coordinates': [position.longitude, position.latitude]
        }
      }
      this.updateDeviceAndFeature(feature, device, position)
      return feature
    },
    updateDevice(position, feature, device) {
      lnglat.updateDevice(position, feature, device)
    },
    updateFeature(feature, position) {
      layerManager.updateFeature(feature, position)
    },
    updateCurrentRoute() {
      layerManager.updateCurrentRouteSource(this.selected.currentTrip)
    },
    updateDeviceAndFeature(feature, device, position) {
      this.updateDevice(position, feature, device)
      this.updateFeature(feature, position)
      if (this.selected && this.selected.id === device.id && !this.historyMode && this.loadingCount > 2) {
        this.updateCurrentRoute()
      }
    },
    async getDevicesIgnitionOffDate() {
      try {
        return await pinmeapi.getAll()
      } catch (error) {
        Vue.$log.warn(error)
      }
      return []
    },
    processPositions(positions, devicesIgnitionOffDate) {
      for (const position of positions) {
        const device = this.devices.find(d => d.id === position.deviceId)
        if (!device) {
          console.warn('no device found for', position, 'devices.length', this.devices.length)
          continue
        }
        if (position.attributes.fuel) {
          checkFuelThresholds(position.attributes.fuel, device)
        }
        let feature = this.findFeatureByDeviceId(position.deviceId)
        if (!feature) {
          if (devicesIgnitionOffDate && devicesIgnitionOffDate.length) {
            const deviceIgnitionOff = devicesIgnitionOffDate.find(d => d.deviceId === device.id)
            if (!position.attributes.ignition && deviceIgnitionOff) {
              this.$store.dispatch('user/setDeviceLastIgnOff', { device, lastStop: deviceIgnitionOff.ignitionOffDate })
            }
          }
          feature = this.positionToFeature(position, device)
          this.positionsSource.features.push(feature)
        } else {
          if (this.shouldAnimate(feature, position)) {
            this.$log.debug('startAnimation', device.name)
            this.animateTo(feature, position)
          } else {
            feature.geometry.coordinates = [position.longitude, position.latitude]
            feature.properties.course = position.course
            if (lnglat.popUps[device.id]) {
              lnglat.popUps[device.id].setLngLat(feature.geometry.coordinates)
            }
          }
          if (device.currentTrip) {
            if (device.currentTrip.length === 20) {
              device.currentTrip.pop()
            }
          } else {
            device.currentTrip = []
          }
          if (device.route &&
            new Date(position.fixTime).toDateString() === new Date().toDateString()) {
            device.route.push(position)
          }
          device.currentTrip.unshift(position)
          this.updateDeviceAndFeature(feature, device, position)
        }
      }
    },
    createGeofenceFeature(geofence) {
      console.log('createGeofenceFeature', geofence)
      vm.$store.state.user.geofences.push(geofence)
      this.$static.draw.deleteAll()
      const featureGeojson = geofencesLayer.getFeatureGeojson(geofence)
      if (featureGeojson.properties.icon) {
        this.geofencesSource.features.push(geofencesLayer.getFeatureCircleGeojson(geofence))
      }
      this.geofencesSource.features.push(featureGeojson)
      this.refreshGeofences()
      const type = getGeofenceType(geofence)
      serverBus.$emit('message', this.$t('map.' + type + '_created'), 'success')
    },
    drawCreate(e) {
      this.$log.debug(e)
      if (!this.$store.state.map.showPOIs) {
        this.$store.dispatch('map/togglePOIs')
      }
      const data = this.$static.draw.getAll()
      this.geofenceMode = 'create'
      this.geofence = { area: e.lngLats
        ? 'CIRCLE (' + e.lngLats[0].lat + ' ' + e.lngLats[0].lng + ', 100)'
        : lnglat.getArea(data) }
      this.geofenceName = ''
      this.geofenceDescription = ''
      this.geofenceGroups = []
      this.geofenceSpeedLimit = 0
      this.geofenceIcon = 'marker'
      this.geofenceColor = '#3232b4'
      this.geofenceFill = true
      this.geofenceDistance = 100
    },
    errorCreating() {
      console.log('errorCreating')
      this.$static.draw.deleteAll()
      this.geofence = undefined
    },
    drawDelete() {
    },
    drawModeChange(e) {
      this.$log.debug(e)
      this.drawMode = e.mode
      if (e.mode === 'draw_point') {
        serverBus.$emit('message', this.$t('map.poi_click_on_map'))
        this.map.once('touchstart', this.drawCreate)
      }
    },
    drawUpdate(e) {
      this.$log.debug(e)
    },
    onMove() {
      if (this.loadingCount > 1) {
        layerManager.refreshLayers()
      }
    },
    onClickTouchPois(e) {
      this.poiPoppup = new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML('<div id="vue-poi-popup"></div>')
        .addTo(this.map)
      const PP = Vue.extend(PoiPopUp)
      const vm = new PP({
        i18n: i18n,
        data: {
          properties: e.features[0].properties,
          lngLat: [e.lngLat.lat, e.lngLat.lng]
        },
        store: store
      })
      vm.$mount('#vue-poi-popup')
    },
    styleImageMissing(e) {
      const imageName = e.id
      if (imageName.startsWith('red') ||
        imageName.startsWith('yellow') ||
        imageName.startsWith('gray') ||
        imageName.startsWith('green')) {
        lnglat.addVehicleImage(imageName)
      } else if (imageName.startsWith('waze|')) {
        const img = new Image(35, 40)
        const wazeImage = imageName.split('|')[1].toLowerCase()
        img.src = `/livemap/stg/${WazeLayer.svcIcons[wazeImage] || wazeImage}`
        img.onload = () => {
          if (!this.map.hasImage(imageName)) { this.map.addImage(imageName, img) }
        }
      } else if (imageName.startsWith('regulatory')) {
        const img = new Image(20, 20)
        img.src = `/github/${imageName}`
        img.onload = () => {
          if (!this.map.hasImage(imageName)) { this.map.addImage(imageName, img) }
        }
      } else if (imageName.startsWith('groupImage')) {
        if (!this.map.hasImage(imageName)) {
          const _map = this.map
          this.map.loadImage(`${cdnPoiImages}/image${imageName.split('groupImage')[1]}`, function(error, image) {
            if (error) {
              console.error(error)
            } else {
              if (!_map.hasImage(imageName)) {
                _map.addImage(imageName, image)
              }
            }
          })
        }
      } else {
        lnglat.addImageToMap(
          imageName.slice(0, imageName.length - 6),
          hexToRgb(imageName.slice(imageName.length - 6, imageName.length)),
          imageName).catch(e => this.$log.error('addImageToMap', imageName, e))
      }
    },
    animateTo(feature, position) {
      const line = [feature.geometry.coordinates, [position.longitude, position.latitude]]
      animate(feature, line, position.course)
    },
    ensureToken() {
      if (!this.user.token) {
        this.user.token = crypto.randomUUID()
        traccar.updateUser(this.user.id, this.user)
      }
    }
  }
}
</script>

<style lang="scss" >
  .app-main {
    padding:0 !important;
  }

  @media only screen and (min-width: 768px) {
    .divMapGL {
      border-radius: 8px;
    }
  }

  @media only screen and (max-width: 768px) {
    .mapboxgl-ctrl-group > button {
     width: 42px;
     height: 42px;
     background-size: cover;
    }
  }

  .historyPanel {
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
  }
  .currentPositionData {
    padding: 5px;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
    background-image: url('../../icons/fullscreen.svg') !important;
  }
  .mapboxgl-ctrl-icon.mapboxgl-style-switcher {
    background-image: url('../../icons/layers.svg') !important;
  }
   .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
    background-image: url('../../icons/geolocate.svg') !important;
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url('../../icons/zoom-in.svg') !important;
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url('../../icons/zoom-out.svg') !important;
  }
  .mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line {
    background-image: url('../../icons/draw-line.svg') !important;
  }
  .mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon {
    background-image: url('../../icons/draw-square.svg') !important;
  }
  .mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_point {
    background-image: url('../../icons/draw-point.svg') !important;
  }
  .mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash {
    background-image: url('../../icons/draw-trash.svg') !important;
  }
  .mapboxgl-ctrl-icon.mapboxgl-ctrl-map {
    background-image: url('../../icons/map.svg') !important;
  }
  .mapboxgl-ctrl-traffic {
    background-image: url('../../icons/traffic.svg') !important;
  }
  .mapboxgl-ctrl-waze {
    background-image: url('../../icons/waze-icon.svg') !important;
  }
  .mapboxgl-ctrl-signs {
    background-image: url('../../icons/signs.svg') !important;
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
    background-image: url('../../icons/compass-arrow.svg') !important;
  }

  .searchInput {
    font-size: medium;
    width: 500px;
    border: 0;
    background-color: transparent;
    margin: 0;
    height: 40px;
    color: #404040; /* fallback */
    color: rgba(0, 0, 0, 0.75);
    padding: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  button svg {
    fill: #909399;
    opacity: 1;
  }
  *:focus{ outline: none; }

  .pac-container {
    width: 470px !important;
  }

  .mapboxgl-ctrl-logo {
    display: none !important;
  }

</style>
