<template>
  <div style="height: 100%; width: 100%">
    <canvas id="speedChart" @mouseleave="showTooltip = false"></canvas>
    <div v-if="showTooltip" :style="`left:${left}px; top:${top}px`" class="chartTooltip">
      <el-table
        :data="[{}]"
        :show-header="false"
        :cell-style="cellStyle"
      >
        <el-table-column
          v-if="tooltip"
          label=""
          width="8"
        >
        </el-table-column>
        <el-table-column width="230px">
          <div style="padding:0"> {{ title }}</div>
          <el-row v-if="showTripDates(tooltip)" style="padding: 0; margin: 0">
            <el-col style="width: 70px;padding: 0; margin: 0" :span="8" class="tripData">
              <div><i class="far fa-flag"></i> {{ getTripStartDate(tooltip) }}</div>
            </el-col>
            <el-col style="width: 70px;" :span="8" class="tripData">
              <div><i class="fas fa-flag-checkered"></i> {{ getTripEndDate(tooltip) }}</div>
            </el-col>
            <el-col style="width: 50px;" :span="8" class="tripData">
              <div><i class="fas fa-timer" style="color: #13ce66"></i> {{ getTripTime(tooltip) }}</div>
            </el-col>
          </el-row>
          <el-row v-else-if="showStopDates(tooltip)">
            <el-col style="width: 70px;" :span="8" class="tripData">
              <div><i class="far fa-flag"></i> {{ getStopStartDate(tooltip) }}</div>
            </el-col>
            <el-col style="width: 70px;" :span="8" class="tripData">
              <div><i class="fas fa-flag-checkered"></i> {{ getStopEndDate(tooltip) }}</div>
            </el-col>
            <el-col style="width: 50px;" :span="8" class="tripData">
              <div><i class="fas fa-timer" style="color: #F5365C"></i> {{ getStopTime(tooltip) }}</div>
            </el-col>
          </el-row>
          <el-row v-else-if="showIdleDates(tooltip)">
            <el-col style="width: 70px;" :span="8" class="tripData">
              <div><i class="far fa-flag"></i> {{ getIdleStartDate(tooltip) }}</div>
            </el-col>
            <el-col style="width: 70px;" :span="8" class="tripData">
              <div><i class="fas fa-flag-checkered"></i> {{ getIdleEndDate(tooltip) }}</div>
            </el-col>
            <el-col style="width: 50px;" :span="8" class="tripData">
              <div><i class="fas fa-timer" style="color: #F9B218"></i> {{ getIdleTime(tooltip) }}</div>
            </el-col>
          </el-row>
          <div v-if="showAddress(tooltip)" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
            <i class="fas fa-map-pin"></i> {{ getAddress(tooltip) }}
          </div>
          <div v-if="showSpeed(tooltip)" style="float: left">
            <i class="fas fa-tachometer-alt" style="color: #13ce66"></i> {{ getContent(tooltip, speedDataIndex, speedIndex) + ' km/h' }}
          </div>
          <div v-if="showRPM(tooltip)" style="float: left">
            <i class="fab fa-cloudscale" style="color: white"></i> {{ getContent(tooltip, rpmDataIndex, rpmIndex) + ' rpm' }}
          </div>
          <div v-if="showEvents(tooltip)" style="float: left">
            <i class="fas fa-bell" style="color: lightgray"></i> {{ getContent(tooltip, eventDataIndex, eventIndex) }}
          </div>
          <div v-if="showFuel(tooltip)" style="float: left">
            <i class="fas fa-gas-pump" style="color: #13ce66"></i> {{ getContent(tooltip, fuelDataIndex, fuelIndex) }}
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { Chart, Tooltip, CategoryScale, LinearScale, TimeScale, LineController, PointElement, LineElement, BubbleController, Filler } from 'chart.js'
import { getRelativePosition } from 'chart.js/helpers'
// eslint-disable-next-line no-unused-vars
import annotationPlugin from 'chartjs-plugin-annotation' // this unused import must be here
import { serverBus, sharedData, vm } from '@/main'
import * as event from '../../events'
import { mapGetters } from 'vuex'
import 'chartjs-adapter-moment'
import * as utils from '@/utils/utils'

Chart.register(annotationPlugin)

Chart.register(Tooltip, CategoryScale, LinearScale, LineController, PointElement, LineElement, BubbleController, TimeScale, Filler)

export default {
  name: 'SpeedChart',
  props: {
    update: {
      type: Boolean,
      default() { return false }
    }
  },
  data() {
    return {
      showTooltip: false,
      speedDataIndex: '0',
      stopDataIndex: '4',
      idleDataIndex: '6',
      speedIndex: 4,
      rpmDataIndex: '2',
      rpmIndex: 1,
      eventDataIndex: '3',
      eventIndex: 1,
      nearestPOIIndex: 4,
      startTripIndex: 1,
      endTripIndex: 2,
      startStopIndex: 2,
      endStopIndex: 3,
      startIdleIndex: 2,
      endIdleIndex: 3,
      tripTimeIndex: 3,
      addressIndex: 5,
      fuelDataIndex: '1',
      fuelIndex: 1,
      ignition: 0,
      title: '',
      tooltip: {},
      left: 0,
      top: 0,
      currentTrip: null,
      speedChartVisible: true,
      fuelChartVisible: true,
      rpmChartVisible: true,
      eventChartVisible: true,
      tempChartVisible: false
    }
  },
  computed: {
    ...mapGetters(['trips', 'geofences']),
    device() {
      return vm.$data.currentDevice
    }
  },
  watch: {
    update() {
      this.updateChart()
    }
  },
  created() {
    serverBus.$on(event.posChanged, this.onPosChanged)
    serverBus.$on(event.tripChanged, this.onTripChanged)
    serverBus.$on(event.toogleSpeedChart, this.onToogleSpeedChart)
    serverBus.$on(event.toogleFuelChart, this.onToogleFuelChart)
    serverBus.$on(event.toogleRPMChart, this.onToogleRPMChart)
    serverBus.$on(event.toogleEventChart, this.onToogleEventChart)
    serverBus.$on(event.toggleTempChart, this.onToggleTempChart)
  },
  beforeDestroy() {
    serverBus.$off(event.posChanged, this.onPosChanged)
    serverBus.$off(event.tripChanged, this.onTripChanged)
    serverBus.$off(event.toogleSpeedChart, this.onToogleSpeedChart)
    serverBus.$off(event.toogleFuelChart, this.onToogleFuelChart)
    serverBus.$off(event.toogleRPMChart, this.onToogleRPMChart)
    serverBus.$off(event.toogleEventChart, this.onToogleEventChart)
    serverBus.$off(event.toggleTempChart, this.onToggleTempChart)
  },
  mounted() {
    const speedChart = document.getElementById('speedChart')
    if (!speedChart) return
    const ctx = speedChart.getContext('2d')
    if (!this.chart) {
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: [
            {
              backgroundColor: 'rgba(61, 153, 61, 0.5)',
              borderColor: 'rgba(61, 153, 61, 1)',
              borderWidth: 1,
              fill: true,
              data: this.chartData,
              yAxisID: 'speed',
              segment: {
                borderDash: ctx => ctx.p1.parsed.x - ctx.p0.parsed.x > 1000 * 60 * 2 ? [6, 6] : undefined,
                backgroundColor: ctx => ctx.p1.parsed.x - ctx.p0.parsed.x > 1000 * 60 * 2 ? 'rgba(0,0,0,0)' : 'rgba(61, 153, 61, 0.5)'
              },
              spanGaps: true
            },
            {
              borderColor: 'rgba(0, 0, 0, 1)',
              borderWidth: 2,
              fill: false,
              data: this.chartDataFuelSensor,
              type: 'line',
              cubicInterpolationMode: 'monotone',
              yAxisID: 'fuel'
            },
            {
              borderColor: 'rgba(0, 0, 255, 1)',
              borderWidth: 2,
              fill: false,
              data: this.chartDataRPM,
              type: 'line',
              cubicInterpolationMode: 'monotone',
              yAxisID: 'rpm'
            },
            {
              borderColor: 'rgba(255,119,0,1)',
              borderWidth: 2,
              fill: false,
              data: this.chartDataEvents,
              type: 'bubble',
              cubicInterpolationMode: 'monotone',
              yAxisID: 'speed'
            },
            {
              fill: true,
              data: this.chartDataIgnitionOff,
              type: 'line',
              backgroundColor: 'rgb(255, 0, 0)',
              borderWidth: 0,
              stepped: true,
              yAxisID: 'fuel'
            },
            {
              borderColor: 'rgb(0, 0, 255)',
              borderWidth: 2,
              fill: true,
              data: this.chartDataTemperature,
              type: 'line',
              cubicInterpolationMode: 'monotone',
              yAxisID: 'temperature'
            },
            {
              fill: true,
              backgroundColor: 'rgb(249, 178, 24)',
              borderWidth: 0,
              stepped: true,
              data: this.chartDataIdle,
              type: 'line',
              yAxisID: 'fuel'
            }
          ]
        },
        options: {
          animation: false,
          pointBackgroundColor: '#fff',
          onClick: this.chartClickEvent,
          radius: 1,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              backgroundColor: 'rgba(52, 152, 219, 0.8)',
              mode: 'nearest',
              intersect: false,
              bodyFontSize: 14,
              caretSize: 10,
              enabled: false,
              position: 'nearest',
              external: (context) => {
                const chartWidth = context.chart.width
                const tooltipX = context.tooltip.caretX
                if (context.tooltip.opacity === 0) { return }
                this.left = context.tooltip.caretX + (chartWidth - tooltipX < 200 ? -250 : 10)
                if (this.left) { this.showTooltip = true }
                this.tooltip = context.tooltip.body
                const title = context.tooltip.title && context.tooltip.title.length ? context.tooltip.title[0] : ''
                const info = title.split(';')
                this.ignition = info.length ? Number(info[0]) : ''
                this.title = info.length ? info[1] : ''
              },
              callbacks: {
                title(tooltipItems) {
                  return tooltipItems[0].raw.ignition + ';' + tooltipItems[0].raw.x.toLocaleString()
                },
                label(tooltipItem) {
                  const label = tooltipItem.formattedValue
                  switch (tooltipItem.datasetIndex) {
                    case 0:
                      return tooltipItem.datasetIndex +
                        ';' + (tooltipItem.raw.trip ? tooltipItem.raw.trip.trip_start_fixtime.toLocaleString() : '') +
                        ';' + (tooltipItem.raw.trip
                        ? tooltipItem.raw.trip.trip_end_fixtime && tooltipItem.raw.trip.trip_end_fixtime.toLocaleString()
                        : '') +
                        ';' + (tooltipItem.raw.trip ? tooltipItem.raw.trip.trip_driving_time : '') +
                        ';' + parseFloat(label).toFixed(1) +
                        ';' + tooltipItem.raw.address
                    case 3:
                      return tooltipItem.datasetIndex + ';' + tooltipItem.raw.label
                    case 4:
                      return tooltipItem.datasetIndex + ';' + label +
                      ';' + (tooltipItem.raw.stopStart ? tooltipItem.raw.stopStart : '') +
                      ';' + (tooltipItem.raw.stopEnd ? tooltipItem.raw.stopEnd : '') +
                      ';' + (tooltipItem.raw.nearestPOI ? tooltipItem.raw.nearestPOI : '')
                    case 6:
                      return tooltipItem.datasetIndex + ';' + label +
                        ';' + (tooltipItem.raw.idleStart ? tooltipItem.raw.idleStart : '') +
                        ';' + (tooltipItem.raw.idleEnd ? tooltipItem.raw.idleEnd : '') +
                        ';' + (tooltipItem.raw.nearestPOI ? tooltipItem.raw.nearestPOI : '')
                    default:
                      return tooltipItem.datasetIndex + ';' + label
                  }
                }
              }
            },
            annotation: {
              drawTime: 'afterDatasetsDraw', // (default)
              events: ['click'],
              annotations: {
                cursor: {
                  xMin: 0,
                  xMax: 0,
                  mode: 'vertical',
                  type: 'line',
                  backgroundColor: 'rgba(255, 0, 34, 1)',
                  borderColor: 'rgba(255, 0, 34, 1)',
                  borderWidth: 1,
                  xScaleID: 'xAxis'
                },
                box1: {
                  xMin: 0,
                  xMax: 0,
                  type: 'box',
                  backgroundColor: 'rgba(56, 135, 190, 0.5)',
                  borderColor: 'rgb(56, 135, 190)',
                  borderWidth: 1,
                  xScaleID: 'xAxis'
                }
              }
            }},
          aspectRatio: 9,
          maintainAspectRatio: false,
          scales: {
            xAxis: {
              display: true,
              type: 'time',
              time: {
                minUnit: 'hour',
                round: 'second',
                tooltipFormat: 'll HH:mm:ss',
                displayFormats: {
                  minute: 'HH:mm',
                  hour: 'HH:mm',
                  day: 'D MMM'
                }
              }
            },
            speed: {
              position: 'left',
              display: true,
              grid: {
                display: true
              },
              title: {
                display: true,
                text: 'Km/h'
              }},
            rpm: {
              position: 'left',
              display: false,
              grid: {
                display: false
              },
              title: { text: 'rpm', display: true }
            },
            fuel: {
              display: true,
              grid: {
                display: false
              },
              type: 'linear',
              position: 'right',
              title: { text: 'Fuel %', display: true },
              min: 0,
              max: 100
            },
            temperature: {
              display: false,
              grid: {
                display: false
              },
              type: 'linear',
              position: 'right',
              title: { text: 'Temp (ºC)', display: true }
            }
          },
          elements: {
            point: {
              radius: 0 // default to disabled in all datasets
            }
          }
        }
      })
    }
  },
  methods: {
    onPosChanged(i) {
      this.chart.options.plugins.annotation.annotations.cursor.xMin = new Date(sharedData.getPositions()[i].fixTime)
      this.chart.options.plugins.annotation.annotations.cursor.xMax = new Date(sharedData.getPositions()[i].fixTime)
      if (this.trips && this.trips[this.currentTrip]) {
        try {
          this.chart.options.plugins.annotation.annotations.box1.xMin = this.$moment(this.trips[this.currentTrip].positions[0].fixTime).toDate()
          this.chart.options.plugins.annotation.annotations.box1.xMax = this.$moment(this.trips[this.currentTrip].positions.slice(-1)[0].fixTime).toDate()
        } catch (e) {
          this.$log.error(e)
        }
      }
      if (this.chart) {
        if (this.chart.data) {
          this.chart.data.labels = sharedData.getChartLabels()
        }
        if (this.chart.data && this.chart.data.datasets[0]) {
          this.chart.data.datasets[0].data = this.speedChartVisible ? sharedData.getChartData() : []
          if (this.chart.data.datasets[1]) { this.chart.data.datasets[1].data = this.fuelChartVisible ? sharedData.getChartDataFuelLevel() : [] }
          if (this.chart.data.datasets[2]) { this.chart.data.datasets[2].data = this.rpmChartVisible ? sharedData.getChartDataRPM() : [] }
          if (this.chart.data.datasets[3]) { this.chart.data.datasets[3].data = this.eventChartVisible ? sharedData.getChartDataEvents() : [] }
          if (this.chart.data.datasets[4]) { this.chart.data.datasets[4].data = this.speedChartVisible ? sharedData.getChartDataIgnitionOff() : [] }
          if (this.chart.data.datasets[5]) { this.chart.data.datasets[5].data = this.tempChartVisible ? sharedData.getTempChartData() : [] }
          if (this.chart.data.datasets[6]) { this.chart.data.datasets[6].data = this.speedChartVisible ? sharedData.getChartDataIdle() : [] }
        }
        this.chart.update()
      }
    },
    chartClickEvent(e) {
      const canvasPosition = getRelativePosition(e, this.chart)
      serverBus.$emit(event.autoSliderChange, Math.round(this.chart.scales.xAxis.getValueForPixel(canvasPosition.x) / 1000))
    },
    updateChart() {

    },
    onTripChanged(trip) {
      this.currentTrip = trip
      this.updateChart()
      const newPos = this.$moment(this.trips[this.currentTrip].positions[0].fixTime).unix()
      this.$log.debug('autoSliderChange', newPos)
      serverBus.$emit('autoSliderChange', newPos)
    },
    onToogleSpeedChart() {
      if (this.speedChartVisible) {
        this.removeData(0)
        this.speedChartVisible = false
      } else {
        this.addData(0, sharedData.getChartData())
        this.speedChartVisible = true
      }
      this.chart.options.scales.speed.display = !this.speedChartVisible
    },
    onToogleFuelChart() {
      if (this.fuelChartVisible) {
        this.removeData(1)
        this.fuelChartVisible = false
      } else {
        const seriesFuel = sharedData.getChartDataFuelLevel()
        this.addData(1, seriesFuel)
        this.fuelChartVisible = true
        this.fuelScaleVisible = seriesFuel && seriesFuel.lenght > 0
      }
    },
    onToogleRPMChart() {
      if (this.rpmChartVisible) {
        this.removeData(2)
        this.rpmChartVisible = false
      } else {
        const seriesRPM = sharedData.getChartDataRPM()
        this.addData(2, seriesRPM)
        this.rpmChartVisible = true
        this.$log.debug('chart.scales', this.chart.scales.rpm)
        this.chart.scales.rpm.display = true
        this.$log.debug('chart.scales', this.chart.scales.rpm)
        this.chart.update()
      }
    },
    onToogleEventChart() {
      if (this.eventChartVisible) {
        this.removeData(3)
        this.removeData(4)
        this.eventChartVisible = false
      } else {
        this.addData(3, sharedData.getChartDataEvents())
        this.addData(4, sharedData.getChartDataIgnitionOff())
        this.addData(6, sharedData.getChartDataIdle())
        this.eventChartVisible = true
      }
    },
    onToggleTempChart() {
      this.chart.options.scales.temperature.display = !this.tempChartVisible
      this.chart.options.scales.fuel.display = this.tempChartVisible
      if (this.tempChartVisible) {
        this.removeData(5)
        this.tempChartVisible = false
      } else {
        this.addData(5, sharedData.getTempChartData())
        this.tempChartVisible = true
      }
    },
    addData(index, data) {
      this.chart.data.datasets[index].data = data
      this.chart.update()
    },
    removeData(index) {
      if (this.chart.data.datasets[index]) {
        this.chart.data.datasets[index].data = []
        this.chart.update()
      }
    },
    getData(tooltip, index) {
      return tooltip.find(d => d.lines[0].startsWith(index))
    },
    getContent(tooltip, dataIndex, index) {
      const data = this.getData(tooltip, dataIndex)
      if (!data) return ''
      const info = data.lines[0].split(';')
      return info.length > index ? info[index] : ''
    },
    getAddress(tooltip) {
      const data = this.getData(tooltip, this.speedDataIndex)
      const nearestPOIId = this.getContent(tooltip, this.stopDataIndex, this.nearestPOIIndex) || this.getContent(tooltip, this.idleDataIndex, this.nearestPOIIndex)
      const poi = nearestPOIId ? this.geofences.find(g => g.id === Number(nearestPOIId)) : undefined
      const info = data ? data.lines[0].split(';') : ''
      return poi ? poi.name : (info.length > this.addressIndex ? info[this.addressIndex] : '')
    },
    getTripStartDate(tooltip) {
      const startTrip = this.getContent(tooltip, this.speedDataIndex, this.startTripIndex)
      return startTrip.split(' ')[1]
    },
    getTripEndDate(tooltip) {
      const endTrip = this.getContent(tooltip, this.speedDataIndex, this.endTripIndex)
      return endTrip.split(' ')[1]
    },
    getStopStartDate(tooltip) {
      const startStop = this.getContent(tooltip, this.stopDataIndex, this.startStopIndex)
      return new Date(startStop).toLocaleTimeString()
    },
    getStopEndDate(tooltip) {
      const endStop = this.getContent(tooltip, this.stopDataIndex, this.endStopIndex)
      return endStop && new Date(endStop).toLocaleTimeString()
    },
    getStopTime(tooltip) {
      const startStop = this.getContent(tooltip, this.stopDataIndex, this.startStopIndex)
      const endStop = this.getContent(tooltip, this.stopDataIndex, this.endStopIndex)
      return endStop && utils.calculateTimeHHMM((new Date(endStop).getTime() - new Date(startStop).getTime()) / 1000)
    },
    getIdleStartDate(tooltip) {
      const startIdle = this.getContent(tooltip, this.idleDataIndex, this.startIdleIndex)
      return new Date(startIdle).toLocaleTimeString()
    },
    getIdleEndDate(tooltip) {
      const endIdle = this.getContent(tooltip, this.idleDataIndex, this.endIdleIndex)
      return endIdle && new Date(endIdle).toLocaleTimeString()
    },
    getIdleTime(tooltip) {
      const startIdle = this.getContent(tooltip, this.idleDataIndex, this.startIdleIndex)
      const endIdle = this.getContent(tooltip, this.idleDataIndex, this.endIdleIndex)
      return endIdle && utils.calculateTimeHHMM((new Date(endIdle).getTime() - new Date(startIdle).getTime()) / 1000)
    },
    getTripTime(tooltip) {
      const tripTime = this.getContent(tooltip, this.speedDataIndex, this.tripTimeIndex)
      return utils.calculateTimeHHMM(tripTime)
    },
    showTripDates(tooltip) {
      const startTrip = this.getContent(tooltip, this.speedDataIndex, this.startTripIndex)
      return startTrip.length > 0
    },
    showStopDates(tooltip) {
      const startStop = this.getContent(tooltip, this.stopDataIndex, this.startStopIndex)
      return startStop.length > 0 && this.ignition === 0
    },
    showIdleDates(tooltip) {
      const startIdle = this.getContent(tooltip, this.idleDataIndex, this.startIdleIndex)
      return startIdle.length > 0 && this.ignition === 2
    },
    showSpeed(tooltip) {
      const speed = this.getContent(tooltip, this.speedDataIndex, this.speedIndex)
      return speed > 0
    },
    showRPM(tooltip) {
      const rpm = this.getContent(tooltip, this.rpmDataIndex, this.rpmIndex)
      return Number(rpm.replace(',', '')) > 0
    },
    showEvents(tooltip) {
      const data = this.getData(tooltip, this.eventDataIndex)
      return !!data
    },
    showFuel(tooltip) {
      const data = this.getData(tooltip, this.fuelDataIndex)
      return !!data
    },
    showAddress(tooltip) {
      return this.getData(tooltip, this.speedDataIndex) ||
        this.getContent(tooltip, this.stopDataIndex, this.nearestPOIIndex) ||
        this.getContent(tooltip, this.idleDataIndex, this.nearestPOIIndex)
    },
    cellStyle(row) {
      let result = 'padding: 0; '
      if (row.columnIndex === 0) {
        result += 'opacity: 1;background-color: ' + (this.ignition === 0 ? '#ff0022' : (this.ignition === 1 ? '#3D993D' : '#F9B218'))
      }
      if (row.columnIndex === 1) {
        result += 'background-color: black;color: white;font-size: 12px'
      }
      return result
    }
  }
}
</script>
<style>
 .chartTooltip {
   position: absolute;
   border-radius: 5px;
   background-color: black;
 }
 .tripData{
   margin: 0;
   padding: 0;
 }
</style>
