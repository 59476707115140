import { getGeofenceType } from '@/utils/lnglat'

export function getGeofenceGroups(geofence, groups) {
  return groups.filter(g => g.geofences.geofences.includes(geofence.id) ||
    g.geofences.linegeofences.includes(geofence.id) ||
    g.geofences.pois.includes(geofence.id))
}

export function removeFromGroup(geofence, group) {
  const geofenceType = getGeofenceType(geofence)
  switch (geofenceType) {
    case 'poi':
      group.geofences.pois = group.geofences.pois.filter(geofenceId => geofenceId !== geofence.id)
      break
    case 'line':
      group.geofences.linegeofences = group.geofences.linegeofences.filter(geofenceId => geofenceId !== geofence.id)
      break
    case 'geofence':
      group.geofences.geofences = group.geofences.geofences.filter(geofenceId => geofenceId !== geofence.id)
      break
  }
}

export function addToGroup(geofence, group) {
  const geofenceType = getGeofenceType(geofence)
  switch (geofenceType) {
    case 'poi':
      group.geofences.pois.push(geofence.id)
      break
    case 'line':
      group.geofences.linegeofences.push(geofence.id)
      break
    case 'geofence':
      group.geofences.geofences.push(geofence.id)
      break
  }
}
