import Reports from 'fleetmap-reports'
import { getServerHost } from './index'
const serverHost = getServerHost()
const baseUrl = 'https://' + serverHost + '/api'

export const reports = new Reports({
  basePath: baseUrl,
  baseOptions: { withCredentials: true }
})

export async function getTripsPdf(device, start, end) {
  const userData = await reports.getUserData()
  userData.devices = [device]
  const report = await reports.tripReport(start, end, userData)
  return reports.tripReportToPDF(userData, report[0])
}
