import * as lnglat from './lnglat'
import along from '@turf/along'
import bearing from '@turf/bearing'
import { serverBus, vm } from '@/main'
import settings from '../settings'
import Vue from 'vue'
import * as angles from 'angles'
import store from '../store'

const minDistanceRouteMatch = 0.001
let nextKey = ''
let nextMatch = []
angles.SCALE = 360
import layerManager from '@/views/map/mapbox/LayerManager'

export const animatingFeatures = []

export function updateFeature(feature = vm.$static.currentFeature) {
  lnglat.updateBearing(feature)
  if (store.getters.historyMode) {
    layerManager.updateRoutePlayLayerSource(feature)
    layerManager.removeRoutePlayLayer()
    layerManager.addRoutePlayLayer(vm.$static.currentFeature)
  } else {
    updateAnimationLayer()
  }
}

function updateAnimationLayer(_animatingFeatures = animatingFeatures) {
  if (!layerManager.getAnimationLayer()) {
    layerManager.addAnimationLayer()
  }
  layerManager.updateAnimLayerSource(_animatingFeatures)
}

function _animate() {
  const _animatingFeatures = [...animatingFeatures]
  let i = animatingFeatures.length
  while (i--) {
    const feature = animatingFeatures[i]
    const counter = feature.counter
    if (counter < feature.route.length) {
      const coordinates = feature.route[counter]
      feature.geometry.coordinates = coordinates
      if (lnglat.popUps[feature.properties.deviceId]) {
        lnglat.popUps[feature.properties.deviceId].setLngLat(coordinates)
      }
      if (store.getters.historyMode && !vm.$static.map.getBounds().contains(coordinates)) {
        vm.$static.map.panTo(coordinates, { animate: false })
      }
      if (counter < feature.route.length - 1) {
        feature.properties.course = angles.normalize(bearing(feature.route[counter], feature.route[counter + 1]))
      } else {
        feature.properties.course = feature.endingCourse
      }
      if (store.getters.followVehicle) {
        lnglat.centerVehicle(feature)
      }
      lnglat.updateBearing(feature)
      feature.counter++
      updateAnimationLayer(_animatingFeatures)
    } else {
      animatingFeatures.splice(i, 1)
      layerManager.refreshLayers()
      serverBus.$emit('animationEnd', feature.properties.deviceId)
      serverBus.$emit('routeMatchFinished')
    }
  }
  if (animatingFeatures.length) {
    requestAnimationFrame(_animate)
  } else {
    updateAnimationLayer()
    Vue.$log.debug('stopped')
  }
}

// for debugging
let changeColor = 0

export function animate(feature, coordinates, endingCourse) {
  const origin = feature.geometry.coordinates
  const destination = coordinates.slice(-1)
  if (JSON.stringify(origin) === JSON.stringify(destination)) {
    return
  }
  const route = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: coordinates
    }
  }
  if (settings.debugRoutes) {
    const id = Date.now() + ''
    vm.$static.map.addSource(id, {
      type: 'geojson',
      data: route
    })
    vm.$static.map.addLayer({
      id: id,
      type: 'line',
      source: id,
      paint: {
        'line-color': changeColor++ % 2 ? 'red' : 'green',
        'line-width': 5
      }
    })
  }
  animateRoute(route, feature, endingCourse)
}
export function cacheMatch(coordinates, timestamps) {
  const route = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: coordinates
    }
  }
  const lineDistance = lnglat.lineDistance(route)
  if (lineDistance < minDistanceRouteMatch) {
    Vue.$log.debug('ignoring match, distance: ', lineDistance)
    return
  }
  lnglat.matchRoute(route.geometry.coordinates, route.geometry.coordinates.map(function() { return 25 }), timestamps, function(r) {
    if (r.data.matchings && r.data.matchings.length > 0) {
      const matched = r.data.matchings[0].geometry
      if (matched && matched.coordinates.length > 1) {
        nextKey = getHashCode(route)
        nextMatch = matched.coordinates
      }
    }
  })
}
function getHashCode(route) {
  const orig = route.geometry.coordinates[0]
  const dest = route.geometry.coordinates.slice(-1)[0]
  const multi = 100000000
  return '' + Math.floor(orig[0] * multi) +
    Math.floor(orig[1] * multi) + '|' +
    Math.floor(dest[0] * multi) +
    Math.floor(dest[1] * multi)
}
export function animateRoute(route, feature, endingCourse) {
  if (nextKey === getHashCode(route)) {
    route.geometry.coordinates = nextMatch
  }
  followLine(route, feature, endingCourse)
}
export function followLine(route, feature, endingCourse) {
  const arc = []

  const lineDistance = lnglat.lineDistance(route)
  const step = store.getters.historyMode ? 0.005 : lineDistance / 300

  for (let i = 0; i < lineDistance; i += step) {
    const segment = along(route, i, { units: 'kilometers' })
    arc.push(segment.geometry.coordinates)
  }
  if (arc.length > 1) {
    feature.route = arc
    feature.counter = 0
    feature.endingCourse = endingCourse
    const animating = animatingFeatures.length
    if (!animatingFeatures.find(f => f === feature)) {
      animatingFeatures.push(feature)
    }
    requestAnimationFrame(layerManager.refreshLayers)
    if (!animating) { _animate() }
  } else {
    Vue.$log.debug('ignoring', feature.properties.text, arc.length, ' positions')
    serverBus.$emit('routeMatchFinished')
  }
}
