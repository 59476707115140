import { vm } from '@/main'
import styles from '../../../../styles/element-variables.scss'
export default {
  events: {
    id: 'events',
    type: 'symbol',
    source: 'events',
    layout: {
      'text-field': ['get', 'text'],
      'text-size': 40,
      'icon-image': ['get', 'image']
    },
    paint: {
      'text-color': styles.alerts
    },
    filter: ['==', 'selected', true]
  },
  getFeatureGeojson(item, position) {
    const geojson = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: []
      },
      properties: {
        id: item.id,
        description: item.description,
        type: item.type,
        device: item.device,
        driver: vm.$store.state.user.drivers.find(d => d.uniqueId === position.attributes.driverUniqueId),
        content: item.content,
        icon: item.image,
        color: item.color ? item.color : '#3232b4',
        timestamp: position.fixTime,
        speedLimit: item.attributes && item.attributes.speedLimit * 1.852,
        selected: false,
        position: position,
        text: item.attributes.speedLimit ? '' : '\u{25CF}',
        image: `regulatory--maximum-speed-limit-${Math.round(item.attributes && item.attributes.speedLimit * 1.852)}--g1.svg`
      }
    }

    geojson.geometry.coordinates = [parseFloat(position.longitude), parseFloat(position.latitude)]

    return geojson
  },
  findFeatureById(id) {
    return vm.$static.eventsSource.features.find(e => {
      return e.properties.id === id
    })
  },
  findFeatureSelected() {
    return vm.$static.eventsSource.features.find(e => {
      return e.properties.selected === true
    })
  }

}
